//Defult props pass when use Input field
// register
// name
// value
// onChange method
// this four field required for use this.....!

//if you use number then add one more field
// type="number"

//import Error file
import Error from "../Error/Error";
const Input = ({
  id,
  register,
  name,
  passwordvalue,
  currentpasswordvalue,
  type,
  placeholder,
  errortype,
  required,
  maxLength,
  minLength,
  pattern,
  style,
  className,
  labelname,
  labelclassName,
  mainclassName,
  spanclassName,
  defaultValue,
  fronticon,
  icon,
  iconclick,
  disabled,
  accept,
  getvalue,
  data,
  errorclassName,
}) => {
  //trim the entered value and borderstyle

  // const trimvalue = value === undefined ? "" : value.trim();
  const borderstyle = errortype ? "border-danger" : "";

  //number pattern
  const numberregex = /^[0-9\b]+$/;

  //email pattern
  const emailregex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  //space pattern
  const spaceregex = /(?!^ +$)^.+$/;

  // password pattern
  const passwordregex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  //On Paste Space Check
  const spacepaste = new RegExp("/^s+$/");

  //Not Aollow Special character
  const specialregex = /[^\w]|_/g;

  //Not Allow Specific Special character
  const specificspecialregex = /^[@\_\-\/]+$/;

  // apply pattern for input
  const checkpattern = (patternname) => {
    if (patternname === "email") {
      return emailregex;
    }
    if (patternname === "number") {
      return numberregex;
    }
    if (patternname === "text") {
      return spaceregex;
    }
    if (patternname === "password") {
      return passwordregex;
    }
    if (patternname === "space") {
      return spacepaste;
    }

    if (patternname === "specificspecial") {
      return specificspecialregex;
    }
  };
  //check type for email and number
  const checktype = (namefield) => {
    if (namefield === "email") {
      return "text";
    } else if (namefield === "number") {
      return "text";
    } else {
      return namefield;
    }
  };
  return (
    <div className={`${mainclassName}`}>
      {/* label */}
      {labelname && (
        <div>
          <label className={`form-label ${labelclassName}`}>{labelname}</label>
        </div>
      )}
      {/* input */}
      <div className={icon ? "input_password" : "input-group"}>
        {fronticon ? (
          <span className="input-group-text" id="basic-addon2">
            {fronticon}
          </span>
        ) : (
          ""
        )}
        <input
          id={id}
          {...register(name, {
            pattern: checkpattern(pattern),
            required: required ? "This is required message" : "",
            maxLength: maxLength,
            minLength: minLength,
          })}
          className={`${borderstyle} ${className} `}
          style={style}
          name={name}
          type={checktype(type)}
          defaultValue={defaultValue}
          placeholder={placeholder}
          maxLength={maxLength}
          onKeyPress={(event) => {
            if (type === "number" && !/[0-9]/.test(event.key)) {
              event.preventDefault();
            } else if (event.target.value === "" && event.code === "Space") {
              event.preventDefault();
            } else if (pattern === "special" && /[^\w]|_/g.test(event.key)) {
              event.preventDefault();
            }
          }}
          disabled={disabled ? "disabled" : ""}
          accept={accept}
          onPaste={(event) => {
            if (type === "number" && !/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
        />
        {icon ? (
          <span
            className="toggle-password"
            style={{ cursor: "pointer" }}
            // id="basic-addon2"
            onClick={iconclick}
          >
            {icon}
          </span>
        ) : (
          ""
        )}
      </div>
      {/* span  */}
      <div className={`${spanclassName}`}>
        <div className={`text-danger ${errorclassName}`}>
          {data
            ? ""
            : Error(
                name,
                errortype,
                currentpasswordvalue,
                passwordvalue,
                maxLength,
                getvalue
              )}
        </div>
      </div>
    </div>
  );
};
export default Input;
