import { ActionTypes } from "../../Constant/ActionType";

export const SetSkuData = (payload) => {
  return {
    type: ActionTypes.SetSkuData,
    payload: payload,
  };
};
export const SetSkuListing = (payload) => {
  return {
    type: ActionTypes.SetSkuListing,
    payload: payload,
  };
};
export const SetSkuOrder = (payload) => {
  return {
    type: ActionTypes.SetSkuOrder,
    payload: payload,
  };
};
export const SetSkuOrderBy = (payload) => {
  return {
    type: ActionTypes.SetSkuOrderBy,
    payload: payload,
  };
};
export const SetSkuFilterIcon = (payload) => {
  return {
    type: ActionTypes.SetSkuFilterIcon,
    payload: payload,
  };
};
export const SetSkuEyeIcon = (payload) => {
  return {
    type: ActionTypes.SetSkuEyeIcon,
    payload: payload,
  };
};
export const SetSkuFilterListing = (payload) => {
  return {
    type: ActionTypes.SetSkuFilterListing,
    payload: payload,
  };
};
export const SetSkuPageListing = (payload) => {
  return {
    type: ActionTypes.SetSkuPageListing,
    payload: payload,
  };
};
export const SetSkuPerPageListing = (payload) => {
  return {
    type: ActionTypes.SetSkuPerPageListing,
    payload: payload,
  };
};
export const SetSkuListingModal = (payload) => {
  return {
    type: ActionTypes.SetSkuListingModal,
    payload: payload,
  };
};

