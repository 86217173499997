import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";

//Import Images
import footerLogo from "../../Assets/Images/white_logo.png";
import fbIcon from "../../Assets/Images/icon-fb.svg";
import twitterIcon from "../../Assets/Images/icon-twitter.svg";
import instagramIcon from "../../Assets/Images/icon-instagram.svg";
import youtubeIcon from "../../Assets/Images/icon-youtube.svg";
import playStoreIcon from "../../Assets/Images/play_store.svg";
import appStoreIcon from "../../Assets/Images/app_store.svg";
import VideoModal from "../VideoModal/VideoModal";
const Footer = () => {
  const videoRef = useRef(null);

  const [videoStatus, setvideoStatus] = useState(false);
  const handleOpenModal = () => {
    setvideoStatus(true);
  };
  const onHide = () => {
    setvideoStatus(false);
  };

  const pauseVideoss = () => {
    videoRef.current.pause();
    videoRef.current.currentTime = 0;
  };

  return (
    <>
      <footer className="px-4 pt-3 footer-bottom">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-2 col-lg-3 mb-md-0 mb-4 text-lg-left text-center">
              <Link rel="canonical" className="footer-logo" to="/">
                <img
                  src={footerLogo}
                  alt="footer_logo"
                  width="100px"
                  height="100px"
                />
                {/* <div className="text-white mt-3 mb-0 text-center h6">
                  WEAR IT LEGIT. BE AUTHENTIC.
                </div> */}
              </Link>
            </div>
            <div className="col-xl-6 col-lg-9 text-lg-end text-center">
              <div className="pb-2 footer_bottom_links_wrap">
                <ul className="list-unstyled footer_bottom_links">
                  <li>
                    <Link rel="canonical" to="/faq">
                      FAQ
                    </Link>
                  </li>
                  <li onClick={handleOpenModal}>
                    <Link
                      rel="canonical"
                      to="/"
                      data-bs-toggle="modal"
                      data-bs-target="#VideoModal"
                      data-backdrop="static"
                      data-keyboard="false"
                    >
                      HOW IT WORKS
                    </Link>
                  </li>
                  <li>
                    <Link rel="canonical" to="/privacy/policy">
                      PRIVACY
                    </Link>
                  </li>
                  <li>
                    <Link rel="canonical" to="/terms">
                      TERMS
                    </Link>
                  </li>
                  <li>
                    <Link rel="canonical" to="/joinus">
                      Jobs
                    </Link>
                  </li>
                  <li>
                    <Link rel="canonical" to="/contactus" state={"dashboard"}>
                      Contact
                    </Link>
                  </li>
                  <li>
                    <Link rel="canonical" to="/blog">
                      BLOG
                    </Link>
                  </li>
                  <li>
                    <Link rel="canonical" to="/sitemap.html">
                      Site Map
                    </Link>
                  </li>
                  <li>
                    <Link rel="canonical" to="/store/register">
                      Store Sign Up
                    </Link>
                  </li>
                </ul>
                <p className="mb-0 text-white">
                  <div className="" style={{ lineHeight: "1.3" }}>
                    @{new Date().getFullYear()} SEEKRZ.All rights Reserved
                  </div>
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-12">
              <div className="d-flex gap-md-5 gap-2 justify-content-xl-end justify-content-center mt-xl-0 mt-3 flex-lg-nowrap flex-wrap">
                <div>
                  <div className="fw-normal text-uppercase text-white h6">
                    Find Us on Social
                  </div>
                  <ul className="list-unstyled inline_links">
                    <li>
                      <a
                        href="https://www.facebook.com/shopseekrz"
                        target="_blank"
                      >
                        <img
                          id="facebook_soical_link"
                          width="10px"
                          height="10px"
                          src={fbIcon}
                          alt="icon-fb"
                        />
                      </a>
                    </li>
                    <li>
                      <Link rel="canonical" to="/">
                        <img
                          src={twitterIcon}
                          alt="icon-twitter"
                          width="10px"
                          height="10px"
                        />
                      </Link>
                    </li>
                    <li>
                      <a
                        href="https://instagram.com/shopseekrz"
                        target="_blank"
                      >
                        <img
                          id="instagram_soical_link"
                          width="10px"
                          height="10px"
                          src={instagramIcon}
                          alt="icon-instagram"
                        />
                      </a>
                    </li>
                    <li>
                      <Link rel="canonical" to="/">
                        <img
                          src={youtubeIcon}
                          alt="icon-youtube"
                          width="10px"
                          height="10px"
                        />
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="sm-icon">
                  <div className="fw-normal text-uppercase text-white h6 text-uppercase text-center">
                    Download Our App
                  </div>
                  <div className="d-flex flex-lg-nowrap flex-wrap">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.swappitinc.swappit"
                      className="mb-md-0 mb-3 d-inline-block cp ms-2 me-2 sm-icon"
                      style={{ cursor: "default" }}
                      target="_blank"
                    >
                      <img
                        className="cp"
                        id="play_store_button"
                        width="100px"
                        height="100px"
                        src={playStoreIcon}
                        alt="play_store"
                      />
                    </a>
                    <a
                      href="https://apps.apple.com/us/app/seekrz/id1496295519"
                      target="_blank"
                      className="ms-xs-2 me-xs-2 mb-2 d-inline-block"
                      style={{ cursor: "default" }}
                    >
                      <img
                        className="cp"
                        id="app_store_button"
                        width="100px"
                        height="100px"
                        src={appStoreIcon}
                        alt="app_store"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <VideoModal
        play={videoStatus}
        setvideoStatus={setvideoStatus}
        onHide={onHide}
      />
    </>
  );
};

export default Footer;
