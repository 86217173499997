import React from "react";

import playStoreIcon from "../../Assets/Images/play_store.svg";
import appStoreIcon from "../../Assets/Images/app_store.svg";
import qrCodeIcon from "../../Assets/Images/qr-code.png";
import White_Bg_Logo from "../../Assets/Images/white_logo.png";

const MobilePopUp = () => {
  return (
    <div
      className="modal fade dark-modal"
      id="apppopup"
      tabIndex="-2"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-body text-center pt-5 pb-5">
            <button
              type="button"
              className="btn-close close_button"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <img
              src={White_Bg_Logo}
              alt="white-logo"
              style={{ maxWidth: "60%" }}
            />
            <div className="mt-2 d-md-block d-none h6">
              WEAR IT LEGIT.BE AUTHENTIC.
            </div>
            <div className="mt-5">
              <h3 className="text-uppercase fs-5">
                THIS FUNCTIONALITY IS CURRENTLY NOT AVAILABLE ON THE WEB
              </h3>
              <div className="fs-6 fw-normal text-uppercase h6">
                FOR COMPLETE EXPERIENCE DOWNLOAD OUR APP.
              </div>
            </div>
            <div className="mt-4">
              <a
                href="https://play.google.com/store/apps/details?id=com.swappitinc.swappit"
                className="mb-md-0 mb-3 d-inline-block cp"
                style={{ cursor: "default" }}
                target="_blank"
              >
                <img
                  id="play_store_button"
                  className="cp" src={playStoreIcon} alt="play_store" />
              </a>
              <a
                href="https://apps.apple.com/us/app/seekrz/id1496295519"
                target="_blank"
                className="ms-2 me-2"
                style={{ cursor: "default" }}

              >
                <img id="app_store_button" className="cp" src={appStoreIcon} alt="app_store" />
              </a>
            </div>
            <div className="mt-4">
              <h5 className="primary_color">OR</h5>
              <div className="max-200 m-auto">
                <img src={qrCodeIcon} alt="qa code" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobilePopUp;
