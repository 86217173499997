import { ActionTypes } from "../../Constant/ActionType";
const initState = {
  PromoSimilarProductListing: {},
};
const PromoProductReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SetPromoSimilarProductListing:
      return {
        ...state,
        PromoSimilarProductListing: payload,
      };
    default:
      return state;
  }
};
export default PromoProductReducer;
