import { ActionTypes } from "../../Constant/ActionType";

// Filter Icon
export const SetFilterIcon = (payload) => {
    return {
        type: ActionTypes.SetFilterIcon,
        payload: payload,
    };
};

// Filter data
export const setSearchKeyword = (payload) => {
    return {
        type: ActionTypes.setSearchKeyword,
        payload: payload,
    };
};

// Label for home or trending
export const SetLabel = (payload) => {
    return {
        type: ActionTypes.SetLabel,
        payload: payload,
    };
};

// View or hide filter
export const SetFilterEyeIcon = (payload) => {
    return {
        type: ActionTypes.SetFilterEyeIcon,
        payload: payload,
    };
};

// Category Status
export const SetCategoryStatus = (payload) => {
    return {
        type: ActionTypes.SetCategoryStatus,
        payload: payload,
    };
};

// Category Sneaker Expand
export const SetCategorySneakerExpand = (payload) => {
    return {
        type: ActionTypes.SetCategorySneakerExpand,
        payload: payload,
    };
};

// Category Apparel Expand
export const SetCategoryApparelExpand = (payload) => {
    return {
        type: ActionTypes.SetCategoryApparelExpand,
        payload: payload,
    };
};

// Category Status
export const SetCategoryCollectibleExpand = (payload) => {
    return {
        type: ActionTypes.SetCategoryCollectibleExpand,
        payload: payload,
    };
};

// Category Expand Id
export const SetCategoryExpandId = (payload) => {
    return {
        type: ActionTypes.SetCategoryExpandId,
        payload: payload,
    };
};

// Dashboard Data
export const SetDashboardData = (payload) => {
    return {
        type: ActionTypes.SetDashboardData,
        payload: payload,
    };
};

// Dashboard Data
export const SetCurrentPage = (payload) => {
    return {
        type: ActionTypes.SetCurrentPage,
        payload: payload,
    };
};
export const SetTotalPage = (payload) => {
    return {
        type: ActionTypes.SetTotalPage,
        payload: payload,
    };
};