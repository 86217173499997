import { createStore, applyMiddleware } from "redux";
import logger from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import MainReducer from "../Reducer/RootReducer";
import storage from "redux-persist/lib/storage/session";

const persistConfig = {
  key: "userdata",
  storage,
};
const pReducer = persistReducer(persistConfig, MainReducer);

const middleware = applyMiddleware(logger);

const store = createStore(pReducer);

const persistor = persistStore(store);

export default store;
export { persistor };
