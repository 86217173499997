import React, { Suspense, lazy, useRef, useEffect } from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loadder from "../Helper/Loader/Loader";
import SignUpDeals from "../Helper/SignupDeals";
import { SignUpDealModel } from "../Redux/Action/AuthAction/AuthAction";
import SiteMap from "../Helper/SiteMap";
import StoreRegister from "../Components/Public/StoreRegister";
import BlogDetails from "../Components/WithoutLogin/BlogDetails";
import PromoSimilarProduct from "../Helper/PromoSimilarProduct/PromoSimilarProduct";

const Login = lazy(() => import("../Components/Public/Login/Login"));
const Register = lazy(() => import("../Components/Public/Register/Register"));
const ForgotPassword = lazy(
  () => import("../Components/Public/ForgotPassword/ForgotPassword")
);
const ResetPassword = lazy(
  () => import("../Components/Public/ResetPassword/ResetPassword")
);
const RegisterDetails = lazy(
  () => import("../Components/Public/RegisterDetails/RegisterDetails")
);
const RegisterProfile = lazy(
  () => import("../Components/Public/RegisterProfile/RegisterProfile")
);
const PersonalPreference = lazy(
  () => import("../Components/Public/PersonalPreference/PersonalPreference")
);
const LogoPreference = lazy(
  () => import("../Components/Public/LogoPreference/LogoPreference")
);
const NumberVerify = lazy(
  () => import("../Components/Public/NumberVerify/NumberVerify")
);
const EmailVerify = lazy(
  () => import("../Components/Public/EmailVerify/EmailVerify")
);

// const Faq = lazy(() => import("../Components/Public/Faq/Faq"));
const JoinUs = lazy(() => import("../Components/WithoutLogin/JoinUs"));
const Privacy = lazy(() => import("../Components/WithoutLogin/Privacy"));
const Terms = lazy(() => import("../Components/WithoutLogin/Terms"));
const Faq = lazy(() => import("../Components/WithoutLogin/faq"));
const ContactUs = lazy(() => import("../Components/WithoutLogin/ContactUs"));
const Empty = lazy(() => import("../Helper/Empty/Empty"));
const Blog = lazy(() => import("../Components/WithoutLogin/Blog"));

// Common
const Home = lazy(() => import("../Components/Common/Home/Home"));

//Private
const SkuListing = lazy(
  () => import("../Components/Private/SkuListing/SkuListing")
);
const SkuDetails = lazy(
  () => import("../Components/Private/SkuDetails/SkuDetails")
);
const Following = lazy(
  () => import("../Components/Private/Following/Following")
);
const BulkDealsSimilarListing = lazy(
  () =>
    import(
      "../Components/Private/BulkDealsSimilarListing/BulkDealsSimilarListing"
    )
);

const Index = ({ openApp }) => {
  //hooks
  const signupdealref = useRef(null);
  const dispatch = useDispatch();

  //State
  const Loader = useSelector((state) => state.LoaderReducer.Loader);
  const Auth = useSelector((state) => state.AuthReducer.Auth);
  const modelEnable = useSelector(
    (state) => state.AuthReducer.signupmodelenable
  );

  useEffect(() => {
    !Auth.status &&
      modelEnable &&
      setTimeout(() => {
        signupdealref.current?.click();
      }, 30000);
  }, [modelEnable]);

  const handleSignUpDeals = () => {
    dispatch(SignUpDealModel(false));
  };
  return (
    <>
      <BrowserRouter basename="/">
        <>{Loader && Loadder()}</>
        {!Auth.status && (
          <Suspense fallback={Loadder()}>
            <button
              className="d-none"
              data-bs-toggle="modal"
              data-bs-target="#signupdealpopup"
              ref={signupdealref}
              onClick={handleSignUpDeals}
            >
              sign up deal model
            </button>
            <SignUpDeals />
            <Routes>
              <Route
                exact
                path="/"
                element={<Home type="home" openApp={openApp} />}
              />
              <Route
                exact
                path="/nike-air-jordan"
                element={<Home type="home" openApp={openApp} />}
              />
              <Route
                exact
                path="/adidas-yeezy"
                element={<Home type="home" openApp={openApp} />}
              />
              <Route
                exact
                path="/bape"
                element={<Home type="home" openApp={openApp} />}
              />
              <Route
                exact
                path="/supreme"
                element={<Home type="home" openApp={openApp} />}
              />
              <Route
                exact
                path="/travis-scott"
                element={<Home type="home" />}
              />
              <Route
                exact
                path="/chrome-hearts"
                element={<Home type="home" />}
              />
              <Route exact path="/fear-of-god" element={<Home type="home" />} />
              <Route exact path="/sp5der" element={<Home type="home" />} />
              <Route exact path="/vlone" element={<Home type="home" />} />
              <Route
                exact
                path="/sneaker"
                element={<Home type="home" openApp={openApp} />}
              />
              <Route exact path="/streetwear" element={<Home type="home" />} />
              <Route
                exact
                path="/collectibles"
                element={<Home type="home" />}
              />
              <Route path=":id" element={<SkuListing />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/contactus" element={<ContactUs />} />
              <Route path="/skulisting" element={<SkuListing />} />
              <Route path="/skudetails" element={<SkuDetails />}>
                <Route path=":styleName:listing_id" element={<SkuDetails />} />
              </Route>
              <Route path="/joinus" element={<JoinUs />} />
              <Route path="/blog/:id" element={<BlogDetails />} />
              <Route path="/blog" element={<Blog />} />

              <Route path="/privacy/policy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forgotpassword" element={<ForgotPassword />} />
              <Route path="/resetpassword" element={<ResetPassword />} />
              <Route path="/number/verify" element={<NumberVerify />} />
              <Route path="/email/verify" element={<EmailVerify />} />
              <Route path="/register/details" element={<RegisterDetails />} />
              <Route path="/profile" element={<RegisterProfile />} />
              <Route path="/brand/preference" element={<LogoPreference />} />
              <Route
                path="/store/register"
                element={<StoreRegister openApp={openApp} />}
              />
              <Route path="/sitemap.html" element={<SiteMap />} />
              <Route
                path="/personal/preference"
                element={<PersonalPreference />}
              />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Suspense>
        )}
        {Auth.status && (
          <Suspense fallback={Loadder()}>
            <Routes>
              <Route
                exact
                path="/"
                element={<Home type="dashboard" openApp={openApp} />}
              />
              <Route
                exact
                path="/nike-air-jordan"
                element={<Home type="dashboard" openApp={openApp} />}
              />
              <Route
                exact
                path="/adidas-yeezy"
                element={<Home type="dashboard" openApp={openApp} />}
              />
              <Route
                exact
                path="/bape"
                element={<Home type="dashboard" openApp={openApp} />}
              />
              <Route
                exact
                path="/supreme"
                element={<Home type="dashboard" />}
              />
              <Route
                exact
                path="/travis-scott"
                element={<Home type="dashboard" openApp={openApp} />}
              />
              <Route
                exact
                path="/chrome-hearts"
                element={<Home type="dashboard" openApp={openApp} />}
              />
              <Route
                exact
                path="/fear-of-god"
                element={<Home type="dashboard" openApp={openApp} />}
              />
              <Route
                exact
                path="/sp5der"
                element={<Home type="dashboard" openApp={openApp} />}
              />
              <Route
                exact
                path="/vlone"
                element={<Home type="dashboard" openApp={openApp} />}
              />
              <Route
                exact
                path="/sneaker"
                element={<Home type="dashboard" openApp={openApp} />}
              />
              <Route
                exact
                path="/streetwear"
                element={<Home type="dashboard" openApp={openApp} />}
              />
              <Route
                exact
                path="/collectibles"
                element={<Home type="dashboard" openApp={openApp} />}
              />
              <Route path=":id" element={<SkuListing />} />
              <Route path="/skulisting" element={<SkuListing />} />
              <Route path="/skudetails" element={<SkuDetails />}>
                <Route path=":styleName:listing_id" element={<SkuDetails />} />
              </Route>
              <Route path="/following" element={<Following />} />
              <Route path="/faq" element={<Faq />} />
              <Route
                path="/bulk-deals/:id"
                element={<BulkDealsSimilarListing />}
              />
              <Route
                path="/promo-raffles/:id"
                element={<PromoSimilarProduct />}
              />
              <Route path="*" element={<Empty />} />
            </Routes>
          </Suspense>
        )}
      </BrowserRouter>
    </>
  );
};

export default Index;
