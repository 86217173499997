import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { SetLoadder } from "../../Redux/Action/LoaderAction/LoaderAction";

// Image Imports
import AltLogoImage from "../../Assets/Images/product_card_alt.png";
import productLike from "../../Assets/Images/product_like.svg";

import { SetPromoSimilarProductListing } from "../../Redux/Action/PromoSimilarProductAction/PromoSimilarProductAction";
import Api from "../../Api/Api";
import HomeSidebar from "../HomeComponents/HomeSidebar";
import HomeHeader from "../HomeComponents/HomeHeader";
import toast from "react-hot-toast";

const PromoSimilarProduct = () => {
  const storedData = useSelector((state) => state.AuthReducer.Auth);
  const loader = useSelector((state) => state.LoaderReducer.Loader);
  const PromoSimilarProductListing = useSelector(
    (state) => state.PromoProductReducer.PromoSimilarProductListing
  );

  //Other
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  //state
  const [sidebarStatus, setsidebarStatus] = useState(false);

  const getListing = (listing) => {
    if (listing < 10) {
      return listing;
    } else {
      let temp = listing.toString().slice(0, 1);
      return `${temp}0+`;
    }
  };

  useEffect(() => {
    getPromoSimilarList();
  }, []);

  const getPromoSimilarList = async () => {
    dispatch(SetLoadder(true));
    const promoClickListingBody = {
      page: 1,
      per_page: 20,
      promo_id: state?.promoData?.id,
    };

    const promoListingBody = {
      page: 1,
      per_page: 20,
      promo: state?.promoData,
    };
    let response;

    const clickResponse = await Api(
      "users/promo/clicked",
      "post",
      storedData.token ? storedData.token : "",
      promoClickListingBody
    );

    response = await Api(
      "dashboard/home",
      "post",
      storedData.token ? storedData.token : "",
      promoListingBody
    );

    if (response && response?.data?.status) {
      dispatch(SetLoadder(false));
      dispatch(SetPromoSimilarProductListing(response?.data?.data));
    } else {
      dispatch(SetLoadder(false));
      toast.error(response?.data?.message, {
        icon: null,
      });
    }
  };

  // ** handle click on promo item
  const handlepromoitem = (data) => {
    if (storedData?.token) {
      let lowerRemoveDash = data.name
        ?.replace(/\s+/g, "-")
        ?.replace(/\//g, "-")
        ?.toLowerCase();
      const promoStateObj = {
        name: data.name.toLowerCase(),
        similarPromoProduct: state?.promoData,
        promoProductData: data,
      };
      navigate(`/${lowerRemoveDash}`, { state: promoStateObj });
    }
  };

  return (
    <>
      <div className="body_wrap">
        {/* Header */}
        <header className="pt-md-4 pt-2 pb-2 p-3 ps-md-4 ps-2 pe-2 px-lg-4 fixed_header header-wrap large_header overflow-hidden">
          <HomeHeader
            statusChange={setsidebarStatus}
            type={storedData.token ? "dashboard" : "home"}
          />
        </header>
        {/* main section  */}
        <section className="body_section ps-lg-4 pe-lg-4 mt-3">
          <div className="text-center text-uppercase">
            <h4>welcome to the new leaf clothing store home</h4>
          </div>
          <div className="container-fluid p-0">
            <div className="wrapper">
              {PromoSimilarProductListing.styles &&
                PromoSimilarProductListing.styles.length > 0 &&
                PromoSimilarProductListing.styles.map((list, index) => (
                  <div
                    className="grid cp"
                    key={index}
                    onClick={() => handlepromoitem(list)}
                  >
                    <span className="black_tab">
                      <div className="card box-shedo-card">
                        <div className="card-body text-center position-relative">
                          <span className="product_item">
                            {getListing(list.active_listings)}
                          </span>
                          <div className="placeholder_image">
                            <img
                              src={list.webp_image || list.image}
                              alt="product image"
                              className="product_image cp"
                              width="100px"
                              height="100px"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = AltLogoImage;
                              }}
                            />
                          </div>
                          <div className="text-uppercase mt-2 cp h6">
                            {list.name}
                          </div>
                          <div
                            className={`d-flex justify-content-between mt-4 w-100`}
                          >
                            <span className="product_like font-18">
                              {list.following_count}{" "}
                              <span className="icon-10 cp">
                                <img
                                  src={productLike}
                                  alt="product_like"
                                  className="v-5"
                                  width="20px"
                                  height="20px"
                                />
                              </span>
                            </span>
                            <span className="product_price position-relative font-18 cp">
                              ${list.price || list.list_price}
                              {"+"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>
                ))}
            </div>
            {PromoSimilarProductListing.styles &&
              PromoSimilarProductListing.styles.length === 0 &&
              !loader && (
                <div className="d-flex justify-content-center calc_center text-uppercase color-black font-18 colorred">
                  <b className="text-center">
                    This Promo is sold out, Try our other Promos. Check back
                    regularly for new Promos.
                  </b>
                </div>
              )}
          </div>
        </section>
        {/* Sidebar */}
        <HomeSidebar
          type={storedData.token ? "dashboard" : "home"}
          status={sidebarStatus}
          statusChange={setsidebarStatus}
        />
      </div>
    </>
  );
};

export default PromoSimilarProduct;
