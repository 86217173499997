import React, { useEffect, useState } from "react";
import Routes from "./Routes/Routes";

import "./Assets/Css/bootstrap.min.css";
import "./Assets/Css/bootstrap.min.css.map";
import "./Assets/Css/fonts.css";
import "./Assets/Css/base.css";
import "./Assets/Css/style.css";
import "./Assets/Css/responsive.css";
import "./Assets/Css/Loader.css";
import "./Assets/Css/myStyle.css";
import "./Assets/Css/NewCss.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Assets/js/custom.js";
import "./Assets/js/bootstrap.bundle";
import "./Assets/js/bootstrap.bundle.js.map";
import "./Assets/js/bootstrap.min.js.map";
import "./Assets/js/jquery.min.js";
import "./Assets/js/bootstrap.min.js";
import ReactGa from "react-ga";
import TagManager from "react-gtm-module";
import { GetData } from "./Api/LocalStorage";
import { useDispatch } from "react-redux";
import { AuthUser } from "./Redux/Action/AuthAction/AuthAction";
import { SetLabel } from "./Redux/Action/DashboardAction/DashboardAction";
import ReactPixel from "react-facebook-pixel";
import Seekrz_round_logo from "./Assets/Images/Seekrz_round_logo.png";
const App = () => {
  const [openApp, setOpenApp] = useState(true);
  const dispatch = useDispatch();

  //Google Tag InIt
  const tagManagerArgs = {
    gtmId: "GTM-NV2JLKQ",
    dataLayerName: "Seekrz",
  };
  TagManager.initialize(tagManagerArgs);

  //Facebook pixel
  useEffect(() => {
    const options = {
      autoConfig: true,
      debug: false,
    };
    ReactPixel.init("734966060843659", options);
    ReactPixel.pageView();
  }, []);

  //init react ga
  useEffect(() => {
    ReactGa.initialize("UA-194571425-1");
  }, []);

  //TO  remove modal if it is open
  window.onhashchange = () => {
    let modalData = document.querySelectorAll(".modal-backdrop");
    for (const item of modalData) {
      item.parentNode.removeChild(item);
    }
    var element = document.getElementsByTagName("body");
    element[0].style.removeProperty("overflow");
    element[0].style.removeProperty("padding-right");
    document.body.classList.remove("open_filter");
  };

  //tab close method
  window.onbeforeunload = (e) => {
    sessionStorage.setItem(
      "usdygfhsgfsudhgvkdjvnjdnbukdshgusdgjsengvgestyh",
      false
    );
  };

  //tab open method
  window.onload = () => {
    sessionStorage.setItem(
      "usdygfhsgfsudhgvkdjvnjdnbukdshgusdgjsengvgestyh",
      true
    );
  };

  //if token then set data
  useEffect(() => {
    if (GetData("data")) {
      dispatch(AuthUser(GetData("data")));
      dispatch(SetLabel("trending"));
    }
  }, []);

  // useEffect(() => {
  //   if (
  //     /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  //       navigator.userAgent
  //     )
  //   ) {
  //     var now = new Date().valueOf();
  //     setTimeout(function () {
  //       if (new Date().valueOf() - now > 100) return;
  //       window.location = "https://seekrz.page.link/fu7j";
  //     }, 30);
  //   }
  // }, []);

  const handleRedirectToApp = () => {
    window.open("https://seekrz.page.link/fu7j", "_self");
    // window.open("https://onelink.to/cuquwk", "_self");
  };

  useEffect(() => {
    const data = window.location.href;
    if (
      window.location.href.includes("skulisting") &&
      window.location.href.includes("%2F")
    ) {
      window.open(decodeURIComponent(window.location.href), "_self");
    }
  }, [window.location.href]);

  return (
    <div className="App">
      <div className="alert bg-dark text-white fade fadess show d-flex align-items-center seekrz-alert">
        <span
          type="button"
          className="btn-close close-white"
          data-bs-dismiss="alert"
          id="close"
          onClick={() => setOpenApp(false)}
        ></span>
        <div className="w-100 ps-sm-3  ps-1 d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <span className="logo-img">
              <img
                src={Seekrz_round_logo}
                alt="logo"
                width="50px"
                height="50px"
              />
            </span>
            <span className=" px-sm-3 px-2">
              Buy exclusive bulk deals and enter raffles only on the app.
            </span>
          </div>
          <div className="btns">
            <button
              className="btn btn-light me-md-2 open-btn"
              type="button"
              onClick={handleRedirectToApp}
            >
              open
            </button>
          </div>
        </div>
      </div>
      <Routes openApp={openApp} />
    </div>
  );
};

export default App;
