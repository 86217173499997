import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/es/integration/react";
import store, { persistor } from "./Redux/Store/Store";
import { CookiesProvider } from "react-cookie";

//if you want to clear your all console log then apply below line
console.log = () => {};

ReactDOM.render(
  <CookiesProvider>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </CookiesProvider>,
  document.getElementById("root")
);
