import { combineReducers } from "redux";
//Storage
import storage from "redux-persist/lib/storage";
//Actions
import { ActionTypes } from "../Constant/ActionType";
//Local Storage
import { GetData } from "../../Api/LocalStorage";

//All Reducer
import AuthReducer from "./AuthReducer/AuthReducer";
import LoaderReducer from "./LoaderReducer/LoaderReducer";
import DashboardReducer from "./DashboardReducer/DashboardReducer";
import DashboardFilterReducer from "./DashboardReducer/DashboardFilterReducer";
import SkuReducer from "./SkuReducer/SkuReducer";
import SkuFilterReducer from "./SkuReducer/SkuFilterReducer";
import HomeReducer from "./HomeReducer/HomeReducer";
import BulkReducer from "./BulkReducer/BulkReducer";
import PromoProductReducer from "./PromoProductReducer/PromoProductReducer";

//Combine All Reducers
const AppReducer = combineReducers({
  AuthReducer,
  LoaderReducer,
  DashboardReducer,
  SkuReducer,
  SkuFilterReducer,
  DashboardFilterReducer,
  HomeReducer,
  BulkReducer,
  PromoProductReducer,
});

const RootReducer = (state, { type, payload }) => {
  if (GetData("data")) {
    switch (type) {
      case ActionTypes.AuthUserLogOut: {
        storage.removeItem("persist:data");
        return AppReducer(undefined, { type, payload });
      }
    }
    return AppReducer(state, { type, payload });
  } else {
    storage.removeItem("persist:data");
    return AppReducer(state, { type, payload });
  }
};
export default RootReducer;
