import { ActionTypes } from "../../Constant/ActionType";
const initState = {
    DashboardFilter: {},
    DashboardSelectedFilter: {},
    TempFilter: {}
};

const DashboardFilterReducer = (state = initState, { type, payload }) => {
    switch (type) {
        case ActionTypes.SetDashboardFilterData:
            return {
                ...state,
                DashboardFilter: payload,
            };
        case ActionTypes.SetDashboardSelectedFilterData:
            return {
                ...state,
                DashboardSelectedFilter: payload,
            };
        case ActionTypes.SetTempFilterData:
            return {
                ...state,
                TempFilter: payload,
            };
        default:
            return state;
    }
};
export default DashboardFilterReducer;
