import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  AuthUser,
  AuthUserLogOut,
  SignUpDealModel,
} from "../../Redux/Action/AuthAction/AuthAction";
import {
  SetDashboardFilterData,
  SetDashboardSelectedFilterData,
} from "../../Redux/Action/DashboardAction/DashboardFilterAction";
import {
  SetSkuOrder,
  SetSkuOrderBy,
  SetSkuEyeIcon,
  SetSkuFilterIcon,
} from "../../Redux/Action/SkuAction/SkuAction";
import {
  SetSkuFilterData,
  SetSkuSelectedFilterData,
} from "../../Redux/Action/SkuAction/SkuFilterAction";
import {
  SetHomeFilterIcon,
  setHomeSearchKeyword,
  SetHomeFilterEyeIcon,
  SetHomeCategoryStatus,
  SetHomeCategorySneakerExpand,
  SetHomeCategoryApparelExpand,
  SetHomeCategoryCollectibleExpand,
  SetHomeSelectedFilterData,
} from "../../Redux/Action/HomeAction/HomeAction";
import {
  SetFilterEyeIcon,
  SetLabel,
  SetFilterIcon,
  SetCategorySneakerExpand,
  SetCategoryApparelExpand,
  SetCategoryCollectibleExpand,
  SetDashboardData,
} from "../../Redux/Action/DashboardAction/DashboardAction";
import { SetLoadder } from "../../Redux/Action/LoaderAction/LoaderAction";

const LogOut = () => {
  //Other
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //Handle Log Out
  const handleLogOut = () => {
    document.body.classList.remove("open_filter");
    dispatch(SetLoadder(true))
    dispatch(SignUpDealModel(true))
    navigate("/");
    dispatch(AuthUserLogOut());
    localStorage.clear();
    // dispatch(AuthUser({}));
    // dispatch(SetDashboardFilterData({}));
    // dispatch(SetDashboardSelectedFilterData({}));
    // dispatch(SetFilterEyeIcon(false));
    // dispatch(SetFilterIcon(false));
    // dispatch(SetLabel("home"));
    // dispatch(SetSkuOrder(""));
    // dispatch(SetSkuOrderBy(""));
    // dispatch(SetSkuFilterIcon(false));
    // dispatch(SetSkuEyeIcon(false));
    // dispatch(SetSkuFilterData({}));
    // dispatch(SetSkuSelectedFilterData({}));
    // dispatch(SetCategorySneakerExpand(true));
    // dispatch(SetCategoryApparelExpand(true));
    // dispatch(SetCategoryCollectibleExpand(true));
    // // Home Components
    // dispatch(SetHomeCategorySneakerExpand(true));
    // dispatch(SetHomeCategoryApparelExpand(true));
    // dispatch(SetHomeCategoryCollectibleExpand(true));
    // dispatch(SetHomeCategoryStatus(false));
    // dispatch(SetHomeFilterIcon(false));
    // dispatch(setHomeSearchKeyword(""));
    // dispatch(SetHomeSelectedFilterData({}));
    // dispatch(SetDashboardData([]));
  };

  const handleCancel = () => {
    document.body.classList.remove("open_filter");
  };

  return (
    <div
      className="modal fade"
      id="logout"
      tabIndex="0"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered modal-md">
        <div className="modal-content">
          <div className="modal-body text-center">
            <div className="mt-2 d-md-block h6">
              ARE YOU SURE YOU WANT TO LOGOUT ?
            </div>
            <div className="p-2 mb-2 mt-1">
              <div className="d-flex justify-content-between m-2">
                <button
                  className="btn btn-outline-secondary w-50 me-3"
                  data-bs-dismiss="modal"
                  onClick={handleCancel}
                >
                  NO
                </button>
                <button
                  className="btn btn-primary btn-primary-red w-50"
                  data-bs-dismiss="modal"
                  onClick={handleLogOut}
                >
                  YES
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogOut;
