import React, { useRef, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Api from "../../Api/Api";
import White_Bg_Logo from "../../Assets/Images/logo.png";
import Input from "../../Shared_Components/Input/Input";

const SignUpDeals = () => {
  const [hideEmail, sethideEmail] = useState(true);
  const [disableEmailBtn, setdisableEmailBtn] = useState(false);
  const [email, setemail] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const navigate = useNavigate();
  const closemodal = useRef();

  const userSignUpPopUPDataArray = [
    // {
    //     id: 0,
    //     text: 'SIGNUP WITH PROMO CODE "LOVESEEKRZ" TO GET $10 IN-APP CREDIT.',
    //     backColor: '#F9DBBF',
    // },
    {
      id: 1,
      text: "YOUR TRUSTED OUTLET FOR STREETWEAR STORES WHERE YOU FIND DEALS AND STEALS.",
      backColor: "#73CDC7",
    },
    {
      id: 2,
      text: "GET UP TO $100 OFF ON WEEKLY DEALS OR JUST MAKE YOUR BEST OFFER.",
      backColor: "#73CD88",
    },
    { id: 3, text: "FREE AUTHENTICATION.", backColor: "#F8BDC9" },
    { id: 4, text: "NO SELLING FEE.", backColor: "#905995" },
    { id: 5, text: "0.5% IN-APP CREDIT FOR EACH TRADE.", backColor: "#3FAEA6" },
  ];

  const handleNavigate = () => {
    closemodal.current.click();
    navigate("/register");
  };

  const handleEmail = async (data) => {
    data.resend = 0;
    let response = await Api("users/claim", "post", "", data);
    if (response.data.status) {
      sethideEmail(false);
      setdisableEmailBtn(true);
      setemail(data.email);
      toast.success(response.data.message, {
        icon: null,
      });
    } else {
      setemail(data.email);
      toast.error(response.data.message, {
        icon: null,
      });
    }
  };
  const handleResendOtp = async () => {
    if (disableEmailBtn) {
      let data = {};
      data.resend = 1;
      data.email = email;
      let response = await Api("users/claim", "post", "", data);
      if (response.data.status) {
        toast.success(response.data.message, {
          icon: null,
        });
      } else {
        toast.error(response.data.message, {
          icon: null,
        });
      }
    }
  };
  useEffect(() => {
    if (watch("email") !== "") {
      if (email == watch("email")) {
        console.log("if", email, watch("email"));
        setdisableEmailBtn(true);
      } else {
        console.log("else", email, watch("email"));
        setdisableEmailBtn(false);
      }
    }
  }, [watch("email")]);
  return (
    <div
      className="modal fade"
      id="signupdealpopup"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-body text-center pt-5 pb-3">
            <button
              type="button"
              className="btn-close close_button"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref={closemodal}
            ></button>
            <img
              src={White_Bg_Logo}
              alt="white-logo"
              style={{ maxWidth: "60%" }}
            />
            <div className="mt-2 d-md-block d-none h6">
              WEAR IT LEGIT.BE AUTHENTIC.
            </div>
            <div className="mt-4 mb-3">
              {userSignUpPopUPDataArray.map((item, index) => (
                <div
                  className="signupdealbox"
                  key={index}
                  style={{
                    border: `5px solid ${item.backColor}`,
                    color: "black",
                  }}
                >
                  {item.text}
                </div>
              ))}
              {hideEmail ? (
                <>
                  <div className="mt-3">
                    <div
                      className="primary_red_color"
                      style={{ fontSize: "17px", fontWeight: "bold" }}
                    >
                      Get $10 in app credit code in your email.
                    </div>
                    <h5 className="mt-1 primary_red_color">
                      Code sent to email:
                      {/* <span className="ms-1">
                                            {email}
                                        </span> */}
                    </h5>
                  </div>
                  <form onSubmit={handleSubmit(handleEmail)}>
                    <div className="d-flex align-items-start">
                      <Input
                        mainclassName="w-100"
                        name="email"
                        className="form-control black-border"
                        placeholder="EMAIL"
                        register={register}
                        required={true}
                        errortype={errors.email?.type}
                        maxLength={40}
                        pattern="email"
                      />
                    </div>
                    <div className="primary_red_color mt-2">
                      *IF YOU ARE NOT RECEIVING EMAILS THEN PLEASE CHECK YOUR
                      SPAM/JUNK FOLDER. MARK EMAILS FROM SEEKRZ.COM AS NOT SPAM.
                    </div>
                    <button
                      type="submit"
                      className="btn btn btn-primary btn-primary-red ms-3 text-uppercase px-5 my-2"
                      disabled={disableEmailBtn}
                    >
                      Claim
                    </button>
                  </form>
                  <div>
                    {/* <div> NEET IT ONE MORE TIME?
                                    <span className={!disableEmailBtn ? "ms-1 primary_red_color disabled-link" : "ms-1 primary_red_color cp"} onClick={handleResendOtp}>
                                        RSEND
                                    </span>
                                </div> */}
                    <div>
                      By providing email you agree to receive deals and steals
                      emails. you can unsubscribe any time. view{" "}
                      <span
                        className="primary_red_color cp"
                        data-bs-dismiss="modal"
                        onClick={() => navigate("/privacy/policy")}
                      >
                        {" "}
                        Privacy Policy
                      </span>{" "}
                      and{" "}
                      <span
                        className="primary_red_color cp"
                        data-bs-dismiss="modal"
                        onClick={() => navigate("/terms")}
                      >
                        Term and Conditions
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <div
                  className="primary_red_color mt-2 mb-2"
                  style={{ fontSize: "16px", fontWeight: "bold" }}
                >
                  “WELCOME. YOU CAN USE “LOVESEEKRZ” CODE DURING SIGNUP TO CLAIM
                  $10 IN-APP CREDIT.”
                </div>
              )}
              <button
                className="btn-login w-100 text-uppercase"
                onClick={handleNavigate}
              >
                Sign up
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpDeals;
