import React, { useRef } from "react";
import video from "../../Assets/Video/App_Promo.mp4";

const VideoModal = (props) => {
  const videoRef = useRef(null);

  const pauseVideoss = () => {
    videoRef.current.pause();
    videoRef.current.currentTime = 0;
    props.onHide();
  };

  return (
    <div
      className="modal fade dark-modal full_width"
      id="VideoModal"
      tabIndex="-1"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered modal-fullscreen">
        <div className="image-modal-content">
          <div className="modal-body d-flex flex-column align-items-center justify-content-center text-center pt-5 pb-5">
            <button
              type="button"
              className="btn-close close_button"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={pauseVideoss}
            />
            {props.play && (
              <video
                style={{ maxWidth: "100%", maxHeight: "100%" }}
                controls
                autoPlay
                ref={videoRef}
              >
                <source src={video} type="video/mp4"></source>
              </video>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoModal;
