import axios from "axios";
import config from "./config";
//dev
// const baseUrl = "http://dev.seekrz.com/seekrz/api/v1";
//staging
// const baseUrl = "http://staging.seekrz.com/seekrz/api/v1";
//prod
// const baseUrl = "https://seekrz.com/seekrz/api/v1";

const Api = async (url, type, token, data, resettoken) => {
  const apiObject = (url, type, data) => {
    const obj = {
      url: `${config.BASEURL.PROD}/${url}`,
      method: type,
      headers: resettoken
        ? { Authorization: `Bearer ${resettoken}` }
        : token
          ? { Authorization: `${token}` }
          : "",
      data: data,
    };
    return obj;
  };
  try {
    const apiResponse = await axios(apiObject(url, type, data));
    return apiResponse;
  } catch (err) {
    if (!err.response) {
      const obj = {
        data: {
          data: {},
          message: "Network Error",
          status: 0,
          title: "Error",
        },
      };
      return obj;
    } else if (
      err.response.data.message === "Invalid token" ||
      err.response.data.message === "jwt expired"
    ) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/";
    } else {
      return err.response;
    }
  }
};

export default Api;
