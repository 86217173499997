import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

// Image Imports
import grayLogo from "../../Assets/Images/white_logo.png";
import whiteCross from "../../Assets/Images/white_close.svg";
import homeLeftIcon from "../../Assets/Images/icon_left_home.svg";
import listLeftIcon from "../../Assets/Images/icon_left_list.svg";
import messageLeftIcon from "../../Assets/Images/icon_left_message.svg";
import activeLeftIcon from "../../Assets/Images/icon_left_active.svg";
import conditionLeftIcon from "../../Assets/Images/icon_left_condition.svg";
import followLeftIcon from "../../Assets/Images/icon_left_follow.svg";
import logoutIcon from "../../Assets/Images/Logout.png";
import MobilePopUp from "../MobilePopUp/MobilePopUp";
import LogOut from "../LogOut/LogOut";

const HomeSidebar = ({ status, statusChange, type }) => {
    const ref = useRef(null);

    const handleClose = () => {
        statusChange(false);
        document.body.classList.remove("open_filter");
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                statusChange(false);
                document.body.classList.remove("open_filter");
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    return (
        <>
            <div className={`left_menu ${status ? "open" : ""}`} ref={status ? ref : null}>
                <div className="p-4 d-flex pb-3">
                    <div className="logo">
                        <img src={grayLogo} alt="gray_logo" width="100px" height="100px" />
                        <div className="text-white mt-2 mb-0 text-center h6">
                            WEAR IT LEGIT. BE AUTHENTIC.
                        </div>
                    </div>
                    <div className="ms-auto">
                        <span className="sidebarClose cp" onClick={handleClose}>
                            <img src={whiteCross} alt="white_close"
                                width="20px"
                                height="20px"
                            />
                        </span>
                    </div>
                </div>
                <ul className="list-unstyled left_menu_side">
                    <li>
                        <Link rel="canonical" id="home" to="/" onClick={handleClose}>
                            <span className="icon-md">
                                <img src={homeLeftIcon} alt="icon_left_home" className="v-6"
                                    width="20px"
                                    height="20px"
                                />
                            </span>
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link rel="canonical" id="listings" to={type == 'home' ? "/login" : "/"} onClick={handleClose} data-bs-toggle={type == "home" ? '' : 'modal'} data-bs-target={type == "home" ? '' : '#apppopup'}>
                            <span className="icon-md">
                                <img src={listLeftIcon} alt="icon_left_list" className="v-6"
                                    width="20px"
                                    height="20px"
                                />
                            </span>
                            Listings
                        </Link>
                    </li>
                    <li>
                        <Link rel="canonical" id="message_center" to={type == 'home' ? "/login" : "/"} onClick={handleClose} data-bs-toggle={type == "home" ? '' : 'modal'} data-bs-target={type == "home" ? '' : '#apppopup'}>
                            <span className="icon-md">
                                <img src={messageLeftIcon} alt="icon_left_message" className="v-6"
                                    width="20px"
                                    height="20px"
                                />
                            </span>
                            message Center
                        </Link>
                    </li>
                    <li>
                        <Link rel="canonical" id="active_trades" to={type == 'home' ? "/login" : "/"} onClick={handleClose} data-bs-toggle={type == "home" ? '' : 'modal'} data-bs-target={type == "home" ? '' : '#apppopup'}>
                            <span className="icon-md">
                                <img src={activeLeftIcon} alt="icon_left_condition" className="v-6"
                                    width="20px"
                                    height="20px"
                                />
                            </span>
                            Active trades
                        </Link>
                    </li>
                    <li>
                        <Link rel="canonical" id="recommendation" to={type == 'home' ? "/login" : "/"} onClick={handleClose} data-bs-toggle={type == "home" ? '' : 'modal'} data-bs-target={type == "home" ? '' : '#apppopup'}>
                            <span className="icon-md">
                                <img src={conditionLeftIcon} alt="icon_left_condition" className="v-13"
                                    width="20px"
                                    height="20px"
                                />
                            </span>
                            Recommendation
                        </Link>
                    </li>
                    <li>
                        <Link rel="canonical" id="following" to={type == 'home' ? "/login" : "/"} onClick={handleClose} data-bs-toggle={type == "home" ? '' : 'modal'} data-bs-target={type == "home" ? '' : '#apppopup'}>
                            <span className="icon-md">
                                <img src={followLeftIcon} alt="icon_left_condition" className="v-6"
                                    width="20px"
                                    height="20px"
                                />
                            </span>
                            following
                        </Link>
                    </li>
                    {type == "dashboard" ?
                        <li>
                            <Link rel="canonical" to="/" onClick={handleClose} data-bs-toggle="modal" data-bs-target="#logout">
                                <span className="icon-md">
                                    <img src={logoutIcon} alt="icon_left_condition" className="v-6"
                                        width="20px"
                                        height="20px"
                                    />
                                </span>
                                Logout
                            </Link>
                        </li> : null}
                </ul>
            </div>
            <LogOut />
            <MobilePopUp />
        </>
    );
};

export default HomeSidebar;