import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import Api from "../../../Api/Api";
import storeLogo from "../../../Assets/Images/seekz-store.png";
import Input from "../../../Shared_Components/Input/Input";
import { useNavigate } from "react-router";
const StoreRegister = ({ openApp }) => {
  const [phone, setphone] = useState("");
  const [error, seterror] = useState("");
  const [selectedCountry, setselectedCountry] = useState("United States");
  const [errorShow, seterrorShow] = useState(false);
  const [offer, setoffer] = useState(true);
  const storeregistermodel = useRef(null);
  const navigate = useNavigate();

  // Register
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // Register User
  const handleRegister = async (data) => {
    if (phone && phone.length >= 11) {
      const submitData = {
        ...data,
        phone: `+${phone}`,
        is_offer: offer,
      };
      let response = await Api(
        "store/submit/new-store-info",
        "post",
        "",
        submitData
      );
      if (response.data.status == 1) {
        reset();
        setphone("+1");
        setoffer(true);
        storeregistermodel.current.click();
      } else {
        toast.error(response.data.message, {
          icon: null,
        });
      }
    } else {
      seterrorShow(true);
      seterror("Please enter your Number");
    }
  };
  const checkNumber = (phone, country) => {
    if (phone === `+${country.dialCode}`) {
      setphone(`+${country.dialCode}`);
    }
    if (phone === "") {
      setphone(`+${country.dialCode}`);
    }
    if (!(selectedCountry === country.name)) {
      setphone(`+${country.dialCode}`);
    }
    setselectedCountry(country.name);
    if (phone.length < 10) {
      seterror("Please enter your Number");
    } else {
      seterrorShow(false);
    }
  };

  return (
    <div
      className="seekrz-store"
      style={{ height: openApp ? "calc(100% - 84px)" : "calc(100%)" }}
    >
      <div className="container">
        <div className="m-0 m-auto p-xl-5 p-2 gy-5">
          <button
            data-bs-toggle="modal"
            data-bs-target="#seekrzstore1"
            ref={storeregistermodel}
            className="d-none"
          >
            store success
          </button>
          <div
            className="modal fade"
            id="seekrzstore1"
            tabIndex="-2"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            data-bs-backdrop="static"
          >
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-body text-center pt-5 pb-3">
                  <div className="mt-2 h6 color-black">
                    Thank you for your interest in learning more about SEEKRZ
                    platform. Our partnerships team will contact you soon to add
                    you to our growing community of stores and resellers and
                    increase your sales and sourcing.
                  </div>
                  <div className="color-black text-end h6">- SEEKRZ TEAM</div>
                  <div className="mt-4 mb-3">
                    <button
                      className="btn-login w-100 text-uppercase"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        setTimeout(() => {
                          navigate("/");
                        }, 500);
                      }}
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-5 mt-xl-0 mt-3 text-center">
            <img
              src={storeLogo}
              alt="store-logo"
              style={{
                width: "140px",
              }}
            />
          </div>
          <div className="row seekrz-store-signup">
            <div className="col-lg-10 mx-auto fw-bold">
              <div>
                <p>Hey Store Owner/Reseller, thank you for checking us out.</p>
                <p>
                  Would you like to create a new sales channel and sell your
                  products across the US with the guarantee that your money and
                  product are always safe, no matter who you sell to?
                </p>
                <p>
                  Would you like to use CC to buy your products and sell them
                  before you pay for them in 30 days?
                </p>
                <p>
                  Would you like to have access to below-market bulk deals in
                  sneakers, streetwear, accessories?
                </p>
                <p>
                  Then look no further and join <strong>SEEKRZ</strong> platform
                  to increase your sales to 50+ vetted stores and resellers (and
                  growing) and always sleep well knowing your transaction is
                  always safe.
                </p>
                <p>
                  <strong>SEEKRZ Stores</strong> is a trading platform where we
                  bring the selling community of stores, resellers, and
                  wholesalers together to buy/sell with each other and
                  individual customers backed by{" "}
                  <strong>SEEKRZ GUARANTEE</strong>.
                </p>
                <p>
                  <strong>SEEKRZ GUARANTEE</strong> acts as an ESCROW between
                  your store and other stores/resellers by keeping the money and
                  inventory safe with built-in security and technology.
                </p>
                <p>
                  Want to make more money? Send us your basic information, and
                  our highly skilled team will contact you to see how we can
                  start you on <strong>SEEKRZ</strong>.
                </p>
              </div>
            </div>
            <div className="col-lg-8 col-12 mx-auto mt-5">
              <form onSubmit={handleSubmit(handleRegister)}>
                <div>
                  <h5 className="text-uppercase">PLEASE ENTER YOUR DETAILS</h5>
                  <div className="">
                    <div className="mt-4">
                      {/* Email  */}
                      <Input
                        name="name"
                        className="form-control"
                        labelname="Name:"
                        labelclassName="mb-2 text-uppercase"
                        register={register}
                        maxLength={40}
                        required={true}
                        errortype={errors.name?.type}
                        errorclassName="text-white"
                      />
                    </div>
                    <div className="mb-4 mt-4">
                      <Input
                        name="storeName"
                        type="text"
                        className="form-control"
                        labelname="Store/reseller Name:"
                        labelclassName="mb-2 text-uppercase"
                        register={register}
                        maxLength={30}
                        required={true}
                        errortype={errors.storeName?.type}
                        errorclassName="text-white"
                      />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="formFile" className="form-label">
                        CONTACT NUMBER
                      </label>
                      <PhoneInput
                        country={"us"}
                        value={phone}
                        onChange={(phone, country) => {
                          setphone(phone), checkNumber(phone, country);
                        }}
                        // inputClass="black-border"
                        inputStyle={{ width: "100%" }}
                        onKeyDown={(e) => {
                          if (e.code === "Enter") {
                            handleRegister();
                          }
                        }}
                        // enableSearch
                        countryCodeEditable={phone !== ""}
                        tabIndex="1"
                        onlyCountries={["us"]}
                      />
                      {error && errorShow && (
                        <div className="mt-1 text-white">{error}</div>
                      )}
                    </div>
                    <div className="mb-4">
                      <Input
                        name="email"
                        type="email"
                        pattern="email"
                        className="form-control"
                        labelname="EMAIL:"
                        labelclassName="mb-2 text-uppercase"
                        register={register}
                        maxLength={40}
                        required={true}
                        errortype={errors.email?.type}
                        errorclassName="text-white"
                      />
                    </div>
                    <div className="mb-4">
                      <Input
                        name="instagram"
                        className="form-control"
                        labelname="Instagram:"
                        labelclassName="mb-2 text-uppercase"
                        register={register}
                        maxLength={30}
                        required={true}
                        errortype={errors.instagram?.type}
                        errorclassName="text-white"
                      />
                    </div>
                    <div className="checkbox-block d-flex gap-2 ">
                      <input
                        type="checkbox"
                        name="deal"
                        className="flex-shrink-0"
                        onChange={() => setoffer(!offer)}
                        checked={offer}
                      />
                      <div>
                        <label
                          className="align-self-center"
                          style={{ fontSize: "11px" }}
                        >
                          It is OK to keep me informed with bulk and special
                          deals.
                        </label>
                      </div>
                    </div>
                    <div className="btn-container">
                      <button type="submit">
                        <span>I WANT TO learn more</span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreRegister;
