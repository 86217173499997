import { ActionTypes } from "../../Constant/ActionType";
const initState = {
  FilterIcon: false,
  searchKeyword: "",
  label: "trending",
  FilterEyeIcon: false,
  CategoryStatus: false,
  CategorySneakerExpand: true,
  CategoryApparelExpand: true,
  CategoryCollectibleExpand: true,
  CategoryExpandId: "",
  DashboardData: [],
  currentPage: "",
  totalPage:""
};

const DashboardReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SetDashboardData:
      return {
        ...state,
        DashboardData: payload,
      };
    case ActionTypes.SetFilterIcon:
      return {
        ...state,
        FilterIcon: payload,
      };
    case ActionTypes.setSearchKeyword:
      return {
        ...state,
        searchKeyword: payload,
      };
    case ActionTypes.SetLabel:
      return {
        ...state,
        label: payload,
      };
    case ActionTypes.SetFilterEyeIcon:
      return {
        ...state,
        FilterEyeIcon: payload,
      };
    case ActionTypes.SetCategoryStatus:
      return {
        ...state,
        CategoryStatus: payload,
      };
    case ActionTypes.SetCategorySneakerExpand:
      return {
        ...state,
        CategorySneakerExpand: payload,
      };
    case ActionTypes.SetCategoryApparelExpand:
      return {
        ...state,
        CategoryApparelExpand: payload,
      };
    case ActionTypes.SetCategoryCollectibleExpand:
      return {
        ...state,
        CategoryCollectibleExpand: payload,
      };
    case ActionTypes.SetCategoryExpandId:
      return {
        ...state,
        CategoryExpandId: payload,
      };
    case ActionTypes.SetCurrentPage:
      return {
        ...state,
        currentPage: payload,
      };
      case ActionTypes.SetTotalPage:
        return {
          ...state,
          totalPage: payload,
        };
    default:
      return state;
  }
};
export default DashboardReducer;
