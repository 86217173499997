import { ActionTypes } from "../../Constant/ActionType";

const initState = {
    HomeFilterIcon: false,
    HomeSearchKeyword: "",
    HomeFilterEyeIcon: false,
    HomeFilter: {},
    HomeSelectedFilterData: {},
    HomeCategoryStatus: false,
    HomeCategorySneakerExpand: true,
    HomeCategoryApparelExpand: true,
    HomeCategoryCollectibleExpand: true,
    HomeCategoryExpandId: "",
    HomeTempFilterData: {},
    sortObj: {},
    sortview: "",
    sortdata: "",
    viewby: "",
    viewbyapply: false
};

const HomeReducer = (state = initState, { type, payload }) => {
    switch (type) {
        case ActionTypes.SetHomeFilterIcon:
            return {
                ...state,
                HomeFilterIcon: payload,
            };
        case ActionTypes.setHomeSearchKeyword:
            return {
                ...state,
                HomeSearchKeyword: payload,
            };
        case ActionTypes.SetHomeFilterEyeIcon:
            return {
                ...state,
                HomeFilterEyeIcon: payload,
            };
        case ActionTypes.SetHomeCategoryStatus:
            return {
                ...state,
                HomeCategoryStatus: payload,
            };
        case ActionTypes.SetHomeFilterData:
            return {
                ...state,
                HomeFilter: payload,
            };
        case ActionTypes.SetHomeSelectedFilterData:
            return {
                ...state,
                HomeSelectedFilterData: payload,
            };
        case ActionTypes.SetHomeCategorySneakerExpand:
            return {
                ...state,
                HomeCategorySneakerExpand: payload,
            };
        case ActionTypes.SetHomeCategoryApparelExpand:
            return {
                ...state,
                HomeCategoryApparelExpand: payload,
            };
        case ActionTypes.SetHomeCategoryCollectibleExpand:
            return {
                ...state,
                HomeCategoryCollectibleExpand: payload,
            };
        case ActionTypes.SetHomeCategoryExpandId:
            return {
                ...state,
                HomeCategoryExpandId: payload,
            };
        case ActionTypes.SetHomeTempFilterData:
            return {
                ...state,
                HomeTempFilterData: payload,
            };
        case ActionTypes.SetSortObject:
            return {
                ...state,
                sortObj: payload,
            };
        case ActionTypes.SetSortViewBy:
            return {
                ...state,
                sortview: payload,
            };
        case ActionTypes.SetSort:
            return {
                ...state,
                sortdata: payload,
            };
        case ActionTypes.SetViewBy:
            return {
                ...state,
                viewby: payload,
            };
        case ActionTypes.SetViewByApply:
            return {
                ...state,
                viewbyapply: payload,
            };
        default:
            return state;
    }
};
export default HomeReducer;
