import { ActionTypes } from "../../Constant/ActionType";
const initState = {
  SkuFilter: {},
  SkuSelectedFilter: {},
  SkuDefaultData:{}
};
const SkuFilterReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SetSkuFilterData:
      return {
        ...state,
        SkuFilter: payload,
      };
    case ActionTypes.SetSkuSelectedFilterData:
      return {
        ...state,
        SkuSelectedFilter: payload,
      };
      case ActionTypes.SkuFilterDefaultData:
        return {
          ...state,
          SkuDefaultData: payload,
        };
    default:
      return state;
  }
};
export default SkuFilterReducer;
