import { ActionTypes } from "../../Constant/ActionType";
const initState = {
  BulkDealSimilarListing: {},
};
const BulkReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SetBulkDealSimilarListing:
      return {
        ...state,
        BulkDealSimilarListing: payload,
      };
    default:
      return state;
  }
};
export default BulkReducer;
