import { ActionTypes } from "../../Constant/ActionType";
const initState = {
  Auth: {},
  Register: {},
  signupmodelenable: true
};
const AuthReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case ActionTypes.AuthUser:
      return {
        ...state,
        Auth: payload,
      };
    case ActionTypes.RegisterUserData:
      return {
        ...state,
        Register: payload,
      };
    case ActionTypes.SignUpDealModel:
      return {
        ...state,
        signupmodelenable: payload,
      };

    default:
      return state;
  }
};
export default AuthReducer;
