import { ActionTypes } from "../../Constant/ActionType";

// Filter Data
export const SetDashboardFilterData = (payload) => {
    return {
        type: ActionTypes.SetDashboardFilterData,
        payload: payload,
    };
};

// Selected Filter Data
export const SetDashboardSelectedFilterData = (payload) => {
    return {
        type: ActionTypes.SetDashboardSelectedFilterData,
        payload: payload,
    };
};

// Temp Filter Data
export const SetTempFilterData = (payload) => {
    return {
        type: ActionTypes.SetTempFilterData,
        payload: payload,
    };
};