const config = {
    BASEURL: {
        DEV: "http://dev.seekrz.com/seekrz/api/v1",
        STAGING: "http://staging.seekrz.com/seekrz/api/v1",
        PROD: "https://seekrz.com/seekrz/api/v1"
    },
    NONAUTHURL: {
        DEV: "http://dev.seekrz.com/seekrz",
        STAGING: "http://staging.seekrz.com/seekrz",
        PROD: "https://seekrz.com/seekrz"
    },
    DEEPLINKURL: {
        STAGING: "http://staging.seekrz.com/seekrzweb/#",
        PROD: "https://www.seekrz.com/#",
        DEV: "http://localhost:3000/#"
    },
    INVITEDBY: {
        TR: "TR",
        RE: "RE",
        ST: "ST",
        SBUCKS: "SBUCKS",
        USER: "USER",
        LIST: "LIST",
        REGISTER: "REGISTER"
    }
}
export default config