import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

// Image Imports
import logo from "../../Assets/Images/logo.png";
import iconBar from "../../Assets/Images/hamburger.png";
import darkArchiveIcon from "../../Assets/Images/icon_dark_archive.svg";
import defaultAvatar from "../../Assets/Images/default_avatar.png";

// Other Imports
import Api from "../../Api/Api";
import { SetData } from "../../Api/LocalStorage";
import { AuthUser } from "../../Redux/Action/AuthAction/AuthAction";
import {
  SetCategoryStatus,
  SetFilterEyeIcon,
  SetFilterIcon,
  SetLabel,
  setSearchKeyword,
  SetCategorySneakerExpand,
  SetCategoryApparelExpand,
  SetCategoryCollectibleExpand,
  SetDashboardData,
  SetCurrentPage,
} from "../../Redux/Action/DashboardAction/DashboardAction";
import {
  SetSkuListing,
  SetSkuOrder,
  SetSkuOrderBy,
  SetSkuFilterIcon,
} from "../../Redux/Action/SkuAction/SkuAction";
import { SetSkuSelectedFilterData } from "../../Redux/Action/SkuAction/SkuFilterAction";
import {
  SetDashboardFilterData,
  SetDashboardSelectedFilterData,
  SetTempFilterData,
} from "../../Redux/Action/DashboardAction/DashboardFilterAction";
import { SetLoadder } from "../../Redux/Action/LoaderAction/LoaderAction";
import { SetViewBy, SetViewByApply } from "../../Redux/Action/HomeAction/HomeAction";
import toast from "react-hot-toast";

const HomeHeader = ({
  trade,
  statusChange,
  tradeNumber,
  sellerProfile,
  sellerProfileBackground,
  dashboardFunction,
  setselectedFilterData,
  type,
}) => {
  // State
  const storedData = useSelector((state) => state.AuthReducer.Auth);
  const filterData = useSelector(
    (state) => state.DashboardFilterReducer.DashboardFilter
  );

  //Other
  const dispatch = useDispatch();

  const getUserDetail = async () => {
    let userResponse = await Api("users/profile", "get", storedData.token);
    if (userResponse.data.status) {
      const localBody = {
        token: storedData.token,
        newDevice: storedData.newDevice,
        user: userResponse.data.data,
        status: true,
      };
      //Local Storage
      SetData("data", localBody);
      //Redux
      dispatch(AuthUser(localBody));
    } else {
      toast.error(userResponse.data.message, {
        icon: null,
      });
    }
  };

  // useEffect
  useEffect(() => {
    if (type == "dashboard" && !storedData?.user?.avatar_url) {
      getUserDetail();
    }
  }, []);

  const handleSidebar = () => {
    statusChange(true);
    document.body.classList.add("open_filter");
  };

  const handleClearState = () => {
    dispatch(SetLoadder(true));
    dispatch(SetViewByApply(false));
    dispatch(SetViewBy(""));
    dispatch(SetDashboardData([]));
    dispatch(setSearchKeyword(""));
    // if (type == "home") {
    //   dispatch(SetLabel("trending"));
    // } else {
    //   dispatch(SetLabel("home"));
    // }
    dispatch(SetLabel("trending"));
    dispatch(SetCurrentPage(1))
    dispatch(SetFilterEyeIcon(false));
    dispatch(SetFilterIcon(false));
    dispatch(SetCategoryStatus(false));
    dispatch(SetCategorySneakerExpand(true));
    dispatch(SetCategoryApparelExpand(true));
    dispatch(SetCategoryCollectibleExpand(true));
    //clear skulisting
    dispatch(SetSkuListing({}));
    dispatch(SetSkuOrder(""));
    dispatch(SetSkuOrderBy(""));
    dispatch(SetSkuFilterIcon(false));
    dispatch(SetSkuSelectedFilterData({}));
    let resetData = {
      brand: [],
      Sneaker: false,
      sneaker_size_min:
        filterData.subCategories[
          filterData.subCategories.findIndex(
            (obj) => obj.sub_category === "ALL-SNEAKERS"
          )
        ].sizes[0].id,
      sneaker_size_max:
        filterData.subCategories[
          filterData.subCategories.findIndex(
            (obj) => obj.sub_category === "ALL-SNEAKERS"
          )
        ].sizes[
          filterData.subCategories[
            filterData.subCategories.findIndex(
              (obj) => obj.sub_category === "ALL-SNEAKERS"
            )
          ].sizes.length - 1
        ].id,
      Streetwear: false,
      top_size_min:
        filterData.subCategories[
          filterData.subCategories.findIndex(
            (obj) => obj.sub_category === "ALL-TOPS"
          )
        ].sizes[0].id,
      top_size_max:
        filterData.subCategories[
          filterData.subCategories.findIndex(
            (obj) => obj.sub_category === "ALL-TOPS"
          )
        ].sizes[
          filterData.subCategories[
            filterData.subCategories.findIndex(
              (obj) => obj.sub_category === "ALL-TOPS"
            )
          ].sizes.length - 1
        ].id,
      bottom_size_min:
        filterData.subCategories[
          filterData.subCategories.findIndex(
            (obj) => obj.sub_category === "ALL-BOTTOMS"
          )
        ].sizes[0].id,
      bottom_size_max:
        filterData.subCategories[
          filterData.subCategories.findIndex(
            (obj) => obj.sub_category === "ALL-BOTTOMS"
          )
        ].sizes[
          filterData.subCategories[
            filterData.subCategories.findIndex(
              (obj) => obj.sub_category === "ALL-BOTTOMS"
            )
          ].sizes.length - 1
        ].id,
      accessory_size_min:
        filterData.subCategories[
          filterData.subCategories.findIndex(
            (obj) => obj.sub_category === "ALL-ACCESSORIES"
          )
        ].sizes[0].id,
      accessory_size_max:
        filterData.subCategories[
          filterData.subCategories.findIndex(
            (obj) => obj.sub_category === "ALL-ACCESSORIES"
          )
        ].sizes[
          filterData.subCategories[
            filterData.subCategories.findIndex(
              (obj) => obj.sub_category === "ALL-ACCESSORIES"
            )
          ].sizes.length - 1
        ].id,
      Collectibles: false,
      collectible_size:
        filterData.subCategories[
          filterData.subCategories.findIndex(
            (obj) => obj.sub_category === "ALL-COLLECTIBLES"
          )
        ].sizes[0].id,
      condition_min: filterData.conditions[0].pcondition_id,
      condition_max:
        filterData.conditions[filterData.conditions.length - 1].pcondition_id,
      gender: 1,
    };
    dispatch(SetDashboardSelectedFilterData(resetData));
    if (dashboardFunction) {
      dashboardFunction("trending", '', 1, "");
      // dashboardFunction(type == "home" ? "trending" : "home", 1, "");
      setselectedFilterData(resetData);
    }
  };

  return (
    <>
      <div className="header_top">
        <div className="container-fluid p-md-0 pe-0">
          <div className="row align-items-md-start align-items-center logo-name-set">
            <div className="col-auto p-0">
              <span className="sidebar cp" onClick={handleSidebar}>
                <img src={iconBar} alt="toggle icon"
                  width="50px" height="50px"
                />
              </span>
            </div>
            <Link rel="canonical"
              className="col-auto ps-xs-2 pe-xs-2 ps-0 pe-0"
              to={"/"}
              onClick={handleClearState}
              id="home_ga"
            >
              <span>
                <img src={logo} alt="logo" className="logo-295" width="100px" height="100px" />
              </span>
              <div
                className="mt-2 d-md-block text-center h6 font12"
                style={{ color: "black" }}
              >
                WEAR IT LEGIT. BE AUTHENTIC.
              </div>
            </Link>
            {type == "home" ? (
              <>
                <div className="col-auto ms-auto pe-md-2 ps-0 mt-4 header-login">
                  <ul className="list-unstyled link-list">
                    <li>
                      <Link rel="canonical" to="/login">Login</Link>
                    </li>
                    <li>
                      <Link rel="canonical" to="/register">Sign Up</Link>
                    </li>
                  </ul>
                </div>
                {trade ?
                  <div className="col-auto ms-5 position-relative width-60">
                    <div className="avatar-64 m-auto cp" style={{ border: "5px solid #dedede" }}>
                      <Link rel="canonical" to="/login">
                        <img src={sellerProfile} alt="avatar_image" className="avatar_image header-loginlogo" style={{
                          backgroundColor: `${sellerProfileBackground}`,
                          border: "5px solid white",
                        }}
                          width="50px"
                          height="50px"
                        />
                      </Link>
                    </div>
                    <span className="change_product">
                      {tradeNumber}
                      <span className="ms-1 icon-15">
                        <img src={darkArchiveIcon} alt="dark image" />
                      </span>
                    </span>
                  </div>
                  :
                  <div className="col-auto ps-md-2 ps-0">
                    <div className="avatar-64 cp">
                      <Link rel="canonical" to="/login">
                        <img src={defaultAvatar} alt="avatar_image"
                          width="50px" height="50px"
                        />
                      </Link>
                    </div>
                  </div>
                }
              </>
            ) : (
              <div
                className={`col-auto ms-auto ${trade ? "position-relative" : ""
                  }`}
              >
                <div
                  className="avatar-64 m-auto cp"
                  style={{
                    border: trade
                      ? "5px solid #dedede"
                      : `7px solid ${storedData &&
                      storedData.user &&
                      storedData.user.halo_color
                      }`,
                  }}
                  data-bs-toggle="modal"
                  data-bs-target="#apppopup"
                >
                  <img
                    src={
                      sellerProfile ||
                      (storedData &&
                        storedData.user &&
                        storedData.user.avatar_url)
                    }
                    alt="avatar_image"
                    className="avatar_image"
                    style={{
                      backgroundColor: `${trade ? sellerProfileBackground : ""
                        }`,
                      border: trade ? "5px solid white" : "none",
                    }}
                    width="50px" height="50px"
                  />
                  {trade ? (
                    <span className="change_product">
                      {tradeNumber}
                      <span className="ms-1 icon-15">
                        <img src={darkArchiveIcon} alt="dark archive" />
                      </span>
                    </span>
                  ) : null}
                </div>
                {!trade ? (
                  <span className="mt-2 fw-bold text-uppercase d-flex justify-content-center">
                    <span>
                      {storedData && storedData.user && storedData.user.handle}
                    </span>
                  </span>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeHeader;
