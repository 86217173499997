import $ from "jquery";

/* password show start */
$(".toggle-password").on("click", function () {
  $(this).toggleClass("hide");
  var input = $($(this).attr("toggle"));
  console.log(input);
  if (input.attr("type") == "password") {
    input.attr("type", "text");
  } else {
    input.attr("type", "password");
  }
});
/* password show end */

/* product spinner */
$(document).ready(function () {
  $(".num-in span").click(function () {
    var $input = $(this).parents(".num-block").find("input.in-num");
    if ($(this).hasClass("minus")) {
      var count = parseFloat($input.val()) - 1;
      count = count < 1 ? 1 : count;
      if (count < 2) {
        $(this).addClass("dis");
      } else {
        $(this).removeClass("dis");
      }
      $input.val(count);
    } else {
      var count = parseFloat($input.val()) + 1;
      $input.val(count);
      if (count > 1) {
        $(this).parents(".num-block").find(".minus").removeClass("dis");
      }
    }

    $input.change();
    return false;
  });
});
/* product spinner */

/* size spinner */
$(document).ready(function () {
  $(".num-in-size span").click(function () {
    var arlene2 = new Array("S", "M", "L", "XXL");
    let arrray = arlene2.toString();
    var $input = $(this).parents(".num-block").find("input.in-num-size");
    if ($(this).hasClass("minus")) {
      var count = parseFloat($arrray.val()) - 1;
      console.log(count);
      count = count < 1 ? 1 : count;
      if (count < 2) {
        $(this).addClass("dis");
        console.log(count);
      } else {
        $(this).removeClass("dis");
        console.log(count);
      }
      $input.val(count);
    } else {
      var count = parseFloat($input.val()) + 1;
      $input.val(count);
      if (count > 1) {
        $(this).parents(".num-block").find(".minus").removeClass("dis");
      }
    }

    $input.change();
    return false;
  });
});
/* size spinner */

/* toggle menu */
$(document).ready(function () {
  $(".sidebar").on("click", function () {
    $(".left_menu").toggleClass("open");
  });
  $(".sidebarClose").on("click", function () {
    $(".left_menu").removeClass("open");
  });
});
/* toggle menu */

/* toggle filter */

$(".filter").click(function () {
  $("body").toggleClass("open_filter");
  $(".right_filter").toggleClass("open");
  document.getElementsByTagName("body");
});
$(".filterClose").on("click", function () {
  $(".right_filter").removeClass("open");
  $("body").removeClass("open_filter");
});

$(document).ready(function () {
  $(".sku_filter").on("click", function () {
    $(".sku_filter_div").toggleClass("open");
    $("body").toggleClass("open_filter");
  });
  $(".skufilterClose").on("click", function () {
    $(".sku_filter_div").removeClass("open");
    $("body").removeClass("open_filter");
  });
});

/* toggle filter */

/* slider js */

/* slider js */
// $(window).on("popstate", function () {
//   $(".modal").modal("hide");
//   $(".modal-backdrop").remove();
//   $(".in").remove();
// });
