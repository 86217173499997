import React, { useState } from "react";
import { useParams } from "react-router-dom";
import HeaderLogo from "../../Helper/Header/HeaderLogo";
import HomeSidebar from "../../Helper/HomeComponents/HomeSidebar";
import BlogImage1 from "../../Assets/Images/blog_image_1.png";
import BlogImage2 from "../../Assets/Images/blog_image_2.png";
// import BlogImage3 from "../../Assets/Images/blog-1.png";
// import BlogImage4 from "../../Assets/Images/blog-2.png";
// import BlogImage5 from "../../Assets/Images/blog-3.png";
const BlogDetails = () => {
  const [sidebarStatus, setsidebarStatus] = useState(false);
  const handleChangeStatus = () => {
    setsidebarStatus(!sidebarStatus);
  };
  const param = useParams();
  return (
    <div>
      <HeaderLogo title="" onClick={handleChangeStatus} />
      {param?.id == "2024-Streetwear-Trends-Unleashed" ? (
        <div className="body_wrap">
          <div className="body_section mt-5">
            <div className="blog_main_div">
              <p>
                <b>
                  2024 Streetwear Trends Unleashed: Seekrz's Guide to Supreme,
                  Air Jordan, Yeezy, Nike, Bape,
                </b>{" "}
                Essentials, Hellstar, Denim Tears <b> and More!</b>
              </p>
              <p>
                Welcome to the definitive guide on the hottest streetwear trends
                of 2024, brought to you by Seekrz – your premier streetwear
                trading marketplace. Join us on a fashion-forward journey as we
                explore iconic brands like
                <b> Supreme, Air Jordan, Yeezy, Nike, Bape, and more. </b>
                Discover the latest streetwear trends of 2024 with Essentials,
                Hellstar, Denim Tears, and Sp5der, as these iconic brands
                redefine urban fashion with their cutting-edge designs and
                must-have pieces, ensuring you stay ahead in style."Dive into
                the heart of street fashion with Seekrz, where every trend is at
                your fingertips.
              </p>
              <p>
                <b> Supreme Awakens:</b> Unravel the Streetwear Icon with Seekrz
                In the dynamic world of 2024 street fashion, Supreme stands
                tall, and Seekrz is your exclusive gateway to securing
                limited-edition releases. Elevate your street style with
                sought-after Supreme pieces, accessible at your fingertips
                through our user-friendly platform.
              </p>
              <p>
                <b>Air Jordan's Sky-High Style:</b> Seekrz's Sneaker Paradise
                Awaits Step into 2024 with Air Jordan, and let Seekrz be your
                ultimate sneaker haven. Our platform connects you to authentic
                Air Jordan releases, from timeless classics to the latest drops.
                Elevate your sneaker game and navigate the streets with
                confidence in the most iconic footwear of the year.
              </p>
              <p>
                <b>Yeezy's Streetwear Revolution:</b> Exclusive Styles on Seekrz
                Explore Yeezy's innovative designs and limited releases through
                Seekrz, your go-to destination for exclusive Yeezy styles.
                Uncover the evolution of Kanye West's streetwear vision and set
                the pace for your fashion journey with the hottest Yeezy
                releases available on Seekrz.
              </p>
              <p>
                <b>Nike Swooshes into Streetwear Glory:</b> Seekrz's Premier
                Selection Navigate the streetwear landscape with the latest from
                Nike, an undisputed giant in the fashion industry. Seekrz offers
                a curated selection of Nike's most coveted pieces, from classic
                silhouettes to cutting-edge collaborations. Stay ahead of the
                trend curve with Seekrz as your connection to the pulse of
                Nike's streetwear innovations.
              </p>
              <p>
                <b> Bape's Iconic Aesthetics: </b>Seekrz's Exclusive Bape
                Wonderland Immerse yourself in the bold and iconic designs of
                Bape through Seekrz's carefully curated selection. From
                camouflage patterns to playful graphics, Seekrz is your portal
                to the world of Bathing Ape's streetwear mastery. Elevate your
                style with exclusive Bape finds available only on Seekrz.
              </p>
              <p>
                <b>Dive into Diversity:</b> Seekrz's Marketplace for All
                Streetwear Enthusiasts But the fashion extravaganza doesn't stop
                there! Seekrz hosts a diverse range of streetwear brands,
                ensuring that your style is as unique as you are. Explore
                emerging labels, discover hidden gems, and connect with fellow
                fashion enthusiasts for trading and showcasing your streetwear
                finds.
              </p>
              <p>
                2024 is the year of streetwear, and Seekrz is your ticket to the
                hottest trends from{" "}
                <b>Supreme, Air Jordan, Yeezy, Nike, Bape, and more.</b> Dive
                into the world of urban fashion, trade, and elevate your
                streetwear game with Seekrz – where every street becomes your
                runway, and every fashion find tells a story. Don't just follow
                the trends; set them with Seekrz, your ultimate destination for
                streetwear fashion in 2024.
              </p>
            </div>
          </div>
        </div>
      ) : param?.id == "Valentine-Day-2024" ? (
        <div className="body_section mt-5">
          <div className="blog_main_div">
            <h5 className="text-center mt-5">
              Valentine's Day 2024: Streetwear Gift Guide for Your
              Fashion-Forward Love, Powered by Seekrz
            </h5>
            <p>
              Love is in the air, and if your significant other is a streetwear
              aficionado, it's time to step up your{" "}
              <b>Valentine's Day gift game! </b>
              Let's dive in the trendiest streetwear gift ideas for your
              fashion-forward love by Seekrz, the ultimate streetwear trading
              marketplace app, to help you snag the best deals and steals from
              authentic streetwear stores. Let's dive into the perfect{" "}
              <b>Valentine's Day</b> gifts for the streetwear lover in your
              life. Elevate your and your bae’s street style game in 2024 with
              Essentials, Hellstar, Denim Tears, and Sp5der – the hottest
              streetwear brands making waves this season.
            </p>
            <p>
              <b>Exclusive Supreme Releases: A Touch of Streetwear Luxury </b>
              Surprise your Valentine with a limited-edition piece from Supreme,
              the epitome of streetwear cool. Use Seekrz to explore exclusive
              Supreme releases and find that one-of-a-kind gift that will make
              your loved one's heart skip a beat. With Seekrz, authenticity is
              guaranteed, ensuring your gift is as unique as your love.
            </p>
            <p>
              <b> Air Jordan Kicks: Elevate Their Style from Head to Toe</b>{" "}
              Sneaker love is real, and nothing says "I love you" like a fresh
              pair of Air Jordans. Use Seekrz to discover the latest drops and
              exclusive releases. Your Valentine will walk the streets with
              style, thanks to the authenticity and variety of choices available
              on the Seekrz app.
            </p>
            <p>
              <b> Yeezy: Set the Mood with Fashion Innovation </b>For the
              fashion-forward soul, Yeezy is the go-to brand. Surprise your love
              with exclusive Yeezy styles, carefully curated by Seekrz. The app
              ensures you get the real deal, making your Valentine's Day gift a
              perfect blend of fashion innovation and streetwear sophistication.
            </p>
            <p>
              <b>
                {" "}
                Nike's Swooshing Romance: Seekrz's Premier Selection for
                Valentine's Day
              </b>{" "}
              Explore Nike's romantic collaborations and special releases using
              Seekrz. This Valentine's Day, swoosh into your loved one's heart
              with a carefully selected piece from Seekrz's premier selection of
              Nike streetwear. Authenticity, variety, and style – all in one
              place
            </p>
            <p>
              <b>Bape Wonderland: Find the Perfect Lovestruck Design</b> Immerse
              your Valentine in the iconic and playful designs of Bape. Seekrz
              takes you to a Bape Wonderland where exclusive finds await. From
              heart-patterned hoodies to lovestruck graphics, use the app to
              discover the perfect Bape piece that screams romance and
              individuality.
            </p>
            <p>
              <b>
                Download Seekrz for the Ultimate Streetwear Shopping Experience{" "}
              </b>
              Why settle for ordinary when you can gift extraordinary
              streetwear? Download the Seekrz app now and unlock a world of
              authentic streetwear deals and steals. The app provides a
              user-friendly interface to navigate through the hottest trends and
              discover exclusive offers from trusted streetwear stores. Make
              this Valentine's Day memorable with Seekrz – where every gift
              tells a unique streetwear story. This Valentine's Day, show your
              streetwear-loving partner that you speak their language. Gift them
              the trendiest pieces from Supreme, Air Jordan, Yeezy, Nike, Bape,
              and more – all effortlessly accessible through the Seekrz app.
              Download the app now, and let the streetwear romance unfold. Make
              this Valentine's Day a celebration of love and style with Seekrz.
            </p>
          </div>
        </div>
      ) : param?.id == "Revolutionize-Your-Streetwear-Business" ? (
        <div className="body_section mt-5">
          <div class="blog_main_div">
            <h5 class="text-center mt-5">
              Revolutionize Your Streetwear Business: Join Seekrz for a Safe,
              Seamless, and Profitable Trading Experience!
            </h5>
            <p>
              Are you a streetwear fashion store or reseller looking to elevate
              your business to new heights? Look no further – Seekrz is the
              game-changing marketplace you've been waiting for! We'll unravel
              the incredible benefits of joining the Seekrz community as a
              business partner, where streetwear fashion businesses can unlock
              new sales channels, trade with peace of mind, and access exclusive
              bulk deals that will skyrocket their profits. Download the Seekrz
              App on App Store and Google Play now and join us on the journey to
              transforming your streetwear business with Seekrz.
            </p>
            <p>
              Elevate your retail offerings with the must-have streetwear
              collections of 2024, featuring renowned brands like Essentials,
              Hellstar, Denim Tears, and Sp5der. Reach out to Seekrz Team to get
              access to exclusive inventory of +50 vetted streetwear stores for
              latest in-demand styles.
            </p>
            <p>
              <b>
                Safe and Secure Trading: Your Money and Products Always
                Protected
              </b>{" "}
              At Seekrz, security is our top priority. When you join our
              platform, you gain access to a secure trading environment where
              your money and products are always safeguarded. Say goodbye to
              uncertainties and trade with confidence, knowing that Seekrz has
              implemented robust measures to ensure a safe transaction
              experience for every streetwear business partner.
            </p>
            <p>
              <b>
                Flexible Payment Options: Buy Now, Pay Later with 30-Day Terms
              </b>{" "}
              Seekrz revolutionizes the way you do business by offering a unique
              and flexible payment system. Streetwear fashion stores and
              resellers can use credit cards to purchase products and enjoy the
              freedom to sell them before making payments. With a generous
              30-day payment window, Seekrz empowers you to manage your cash
              flow efficiently and maximize your profits.
            </p>
            <p>
              <b>Access to Exclusive Bulk Deals: Below Market Rates Await</b>{" "}
              Unlock unparalleled opportunities with Seekrz's exclusive bulk
              deals in sneakers, streetwear, and accessories. Our platform
              connects you with a network of vetted stores and resellers,
              ensuring you have access to below-market rates that can
              significantly boost your profit margins. Seize the chance to stock
              up on the hottest trends and elevate your inventory with Seekrz.
            </p>
            <p>
              <b>
                Expand Your Sales Channels: Reach 50+ Vetted Stores and
                Resellers
              </b>{" "}
              Seekrz opens doors to a vast network of 50+ vetted streetwear
              stores and resellers across the United States. Expand your sales
              channels effortlessly and showcase your products to a diverse and
              engaged audience. Whether you are a seasoned business or a budding
              entrepreneur, Seekrz provides the platform to connect, trade, and
              thrive within the streetwear community.
            </p>
            <p>
              <b>
                User-Friendly Interface: Seamlessly Navigate the Seekrz
                Experience
              </b>{" "}
              Joining the Seekrz community is a breeze with our user-friendly
              interface for streetwear enthusiasts and businesses. Effortlessly
              create your storefront, list your products, and start trading
              within minutes. Our platform is designed to streamline your
              experience, allowing you to focus on what you do best – curating
              and selling the hottest streetwear fashion.
            </p>
            <p>
              <b>
                Don't miss out on the opportunity to revolutionize your
                streetwear business!
              </b>{" "}
              Seekrz is not just a trading marketplace; it's a gateway to
              secure, flexible, and profitable business transactions. Join our
              platform today to{" "}
              <b>
                connect with 50+ vetted stores and resellers, access exclusive
                bulk deals,
              </b>{" "}
              and experience a trading environment where your money and products
              are always protected. Elevate your streetwear trading business
              with Seekrz – where every trade is a step towards success!
            </p>
          </div>
        </div>
      ) : param?.id == "Popular-Streetwear-Trends-for-Men-Women-in-2022" ? (
        <div className="body_section">
          <div className="blog_main_div">
            <h5 className="text-center mt-5">
              Popular Streetwear Trends for Men Women in 2022
            </h5>
            <p>
              Last year saw an increase in popularity for loungewear in the
              streetwear scene, but this year is a whole different thing.
              Streetwear’s continued pull in the fashion industry has been
              reinforced by the recent death of Virgil Abloh, issuing in some
              new fashion trends for both men and women in 2022.
            </p>
            <p>
              There are a few new trends that you should be keeping an eye on,
              like gorpcore and preppy themes. As some of the most influential
              fashion themes for streetwear, it’s no surprise that they’re
              making a big comeback this year.
            </p>
            <p>
              Some of the hottest products in men’s and womenswear include
              Prada’s Re-Nylon puffer jacket; Arc’teryx’s Alpha SV jacket, and
              Moncler’s Cuvellier short down jacket. Gucci x North Face
              collection is another example of gorpcore infiltrating high
              fashion. Now let’s take a look at a few of the upcoming streetwear
              outfits for men and women in 2022.
            </p>
            <br />
            <br />
            <p className="text-center ">Streetwear Trends for Men</p>
            <p>
              So, since preppy and gorpcore are both in for streetwear, you
              might be wondering what pieces you should be adding to your
              streetwear style. For preppy, there’s been a huge increase in
              popularity for pieces like the varsity jacket, polos, sweater
              vests, and loafers. While these all work great for both men and
              women, we think some of the more preppy pieces lend well to men’s
              streetwear fashion in particular. As far as gorpcore goes, you
              want to think pieces that incorporate technical fabric and
              functional everyday articles of clothing. This includes pieces
              like gilets, cargo bottoms, and windbreakers, which are all solid
              choices if you’re going for more of a gorpcore look. Indeed, we’re
              seeing an upwards trend in the need for a more traditional styling
              in men’s streetwear fashion in 2022.
            </p>
            <p>
              These new trending styles are defined by some iconic classic
              looks. Major streetwear brands are leaning into these trends for
              men’s clothing too, pushing fleece jackets as a cozy comfortable
              piece to accent any man’s style. In particular, we’re seeing more
              and more styles incorporating contrasting panels, big pockets,
              lots of zippers, and some crazy psychedelic prints. Men’s
              streetwear pant styles have seen a shift, too, toward the baggier
              end. Baggy cargo pants are now a staple look for men’s streetwear
              fashion.
            </p>
            <img src={BlogImage1} alt="blog image" />
            <p>
              <small>ANTONIO MARRAS SPRING 2022</small>
            </p>
            <p className="text-center ">Streetwear Trends for Women</p>
            <p>
              While the trends for men’s streetwear fashion have tilted toward
              the more traditional side of styling, women’s style has seen more
              of a shift toward colorful stock items incorporating denim and
              trousers.
            </p>
            <p>
              One particular trend in women’s streetwear fashion is the gilet,
              which is normally worn with wavy quilting and no collar. However,
              with a return to the classic functionality of certain streetwear
              pieces, we’re seeing more technical properties being incorporated
              into streetwear outfits for women this year.
            </p>
            <p>
              There’s also a huge trend in the popularity of tracksuits for
              streetwear styles, due largely in part to the recent smash hit
              from Netflix “Squid Game.” Nylon tracksuits have seen an uptick in
              trendiness for women’s streetwear fashion, while still
              incorporating some of the more functional accents that have gained
              popularity in 2022. Think lots of pockets, zippers, and reflective
              fabrics. Brighter colors and color blocking have become popular as
              variations of the classic tracksuit streetwear look.
            </p>
            <img src={BlogImage2} alt="blog image" />
            <p>
              <small>BLUMARINE SPRING 2022</small>
            </p>
          </div>
        </div>
      ) : (
        <div>No Blog Found</div>
      )}
      <HomeSidebar
        type="home"
        status={sidebarStatus}
        statusChange={setsidebarStatus}
      />
    </div>
  );
};

export default BlogDetails;
