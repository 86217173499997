import { ActionTypes } from "../../Constant/ActionType";

export const AuthUser = (payload) => {
  return {
    type: ActionTypes.AuthUser,
    payload: payload,
  };
};
export const RegisterUserData = (payload) => {
  return {
    type: ActionTypes.RegisterUserData,
    payload: payload,
  };
};
export const AuthUserLogOut = (payload) => {
  return {
    type: ActionTypes.AuthUserLogOut,
    payload: payload,
  };
};
export const SignUpDealModel = (payload) => {
  return {
    type: ActionTypes.SignUpDealModel,
    payload: payload,
  };
};

