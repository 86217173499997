import React from "react";
import { useNavigate, Link } from "react-router-dom";

import logo from "../../Assets/Images/logo.png";
import toggleIcon from "../../Assets/Images/hamburger.png";
import leftDarkArrow from "../../Assets/Images/left_dak_arrow.svg";
import { useSelector, useDispatch } from "react-redux";

import {
  SetCategoryStatus,
  SetFilterEyeIcon,
  SetFilterIcon,
  SetLabel,
  setSearchKeyword,
  SetCategorySneakerExpand,
  SetCategoryApparelExpand,
  SetCategoryCollectibleExpand,
  SetDashboardData,
} from "../../Redux/Action/DashboardAction/DashboardAction";
import {
  SetSkuListing,
  SetSkuOrder,
  SetSkuOrderBy,
  SetSkuFilterIcon,
} from "../../Redux/Action/SkuAction/SkuAction";
import { SetSkuSelectedFilterData } from "../../Redux/Action/SkuAction/SkuFilterAction";
import { SetDashboardSelectedFilterData } from "../../Redux/Action/DashboardAction/DashboardFilterAction";
import { SetLoadder } from "../../Redux/Action/LoaderAction/LoaderAction";

const HeaderLogo = (props) => {
  //State
  const storedData = useSelector((state) => state.AuthReducer.Auth);
  //other
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //Clear all state
  const handleClearState = () => {
    dispatch(SetLoadder(true));
    dispatch(SetDashboardData([]));
    dispatch(setSearchKeyword(""));
    // if (storedData.token) {
    //   dispatch(SetLabel("home"));
    // } else {
    //   dispatch(SetLabel("trending"));
    // }
    dispatch(SetLabel("trending"));
    dispatch(SetFilterEyeIcon(false));
    dispatch(SetFilterIcon(false));
    dispatch(SetCategoryStatus(false));
    dispatch(SetCategorySneakerExpand(true));
    dispatch(SetCategoryApparelExpand(true));
    dispatch(SetCategoryCollectibleExpand(true));
    //clear skulisting
    dispatch(SetSkuListing({}));
    dispatch(SetSkuOrder(""));
    dispatch(SetSkuOrderBy(""));
    dispatch(SetSkuFilterIcon(false));
    dispatch(SetSkuSelectedFilterData({}));
    dispatch(SetDashboardSelectedFilterData());
    navigate("/");
  };

  return (
    <header className="pt-md-4 pt-2 pb-2 p-3 ps-md-4 ps-2 pe-2 ps-lg-5 pe-lg-5 fixed_header small_header header_bg">
      <div className="header_top">
        <div className="container-fluid p-md-0 pe-0">
          <div className="row align-items-md-start align-items-center">
            <div className="col-auto p-0 cp">
              <a className="sidebar">
                <img
                  src={toggleIcon}
                  alt="toggle icon"
                  onClick={props.onClick}
                />
              </a>
            </div>
            <span
              className="col-auto cp"
              onClick={handleClearState}
              id="home_ga"
            >
              <img
                src={logo}
                alt="logo"
                className="logo-295"
                width="100px"
                height="100px"
              />
              <div
                className="mt-2 d-md-block d-none text-center h6"
                style={{ color: "black", fontFamily: "Roc Grotesk" }}
              >
                WEAR IT LEGIT. BE AUTHENTIC.
              </div>
            </span>
          </div>
        </div>
      </div>
      <div className="header_bottom">
        <div className="container-fluid ps-0 pe-0">
          <div className="row align-items-top">
            <div className="col-md-auto col-12 ps-md-0 order_3 pt-md-0 pt-2">
              <div className="d-flex mt-md-0 mt-2 align-items-center">
                <div className="product_name">
                  <div className="d-flex mt-md-0 mt-2 align-items-center">
                    <a onClick={() => navigate(-1)} className="cp">
                      <span className="icon-50">
                        <img src={leftDarkArrow} alt="left_dak_arrow" />
                      </span>
                    </a>
                    <div className="product_name ms-md-4 ms-3">
                      <h5 className="text-uppercase fs-2 mb-0">
                        {props.title}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderLogo;
