const Error = (string, errortype, currentvalue, passwordvalue) => {
  //trim the value
  let name = string;
  let pvalue = passwordvalue;
  let cvalue = currentvalue;
  //create errormessage
  let errorMessage = "";

  //for required
  if (errortype === "required") {
    if (name === "Confirmpassword") {
      errorMessage = `Please enter confirm password.`;
    } else if (name === "Mobilenumber") {
      errorMessage = `Please enter your mobile number.`;
    } else if (name === "Firstname") {
      errorMessage = `Please enter your first name.`;
    } else if (name === "Lastname") {
      errorMessage = `Please enter your last name.`;
    } else if (name === "EPHandle") {
      errorMessage = `Please enter your EMAIL/PHONE/HANDLE.`;
    } else if (name === "EHandle") {
      errorMessage = `Please enter your EMAIL/HANDLE.`;
    } else if (name === "shoessize") {
      errorMessage = `Please enter your shoes size.`;
    } else if (name === "ShoesChoice") {
      errorMessage = `Please enter your Shoes choice.`;
    } else if (name === "topsize") {
      errorMessage = `Please enter your top size.`;
    } else if (name === "TopChoice") {
      errorMessage = `Please enter your Top choice.`;
    } else if (name === "bottomsize") {
      errorMessage = `Please enter your bottom size.`;
    } else if (name === "BottomChoice") {
      errorMessage = `Please enter your Bottom choice.`;
    } else if (name === "accessoriessize") {
      errorMessage = `Please enter your accessories size.`;
    } else if (name === "AccessoriesChoice") {
      errorMessage = `Please enter your Accessories choice.`;
    } else if (name === "CollectiblesChoice") {
      errorMessage = `Please enter your Collectibles choice.`;
    } else if (name === "code") {
      errorMessage = `Please enter your Verification code.`;
    } else {
      errorMessage = `Please enter your ${name}.`;
    }
  }
  // shoessize
  //for maxlength
  if (errortype === "maxLength") {
    errorMessage = `${name} is too long`;
  }

  //for minlength
  if (errortype === "minLength") {
    if (name === "Phone") {
      errorMessage = `The phone number must have 10 digits`;
    } else if (name === "Confirmpassword") {
      if (pvalue !== cvalue) {
        errorMessage = `Password and Confirm password must be same.`;
      } else {
        errorMessage = `Please enter a valid Confirm password.`;
      }
    } else if (name === "Newpassword") {
      errorMessage = `New password must be 8 character long`;
    } else if (name === "password") {
      errorMessage = `Please enter a valid ${name}.`;
    }
  }
  if (cvalue && cvalue.length > 0 && pvalue && pvalue.length > 0) {
    if (pvalue !== cvalue) {
      errorMessage = `Password and Confirm password must be same.`;
    }
  }
  //for number
  if (name === "number" && errortype === "pattern") {
    errorMessage = `Enter ${name} only.`;
  }

  //for email
  if (name === "email" && errortype === "pattern") {
    errorMessage = `Please enter a valid email`;
  }

  //for password
  if (name === "Password" && errortype === "pattern") {
    errorMessage = `Enter A Valid Password`;
  }

  //space not allowed
  //   if (name === name && errortype === "pattern") {
  //     errorMessage = `${name} is required `;
  //   }

  return errorMessage;
};

export default Error;
