import React, { useState } from 'react'
import HeaderLogo from '../Header/HeaderLogo'
import HomeSidebar from '../HomeComponents/HomeSidebar';
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';

const SiteMap = () => {
    const [sidebarStatus, setsidebarStatus] = useState(false);

    const handleChangeStatus = () => {
        setsidebarStatus(!sidebarStatus);
    };
    return (
        <div className="body_wrap">
            <HeaderLogo title="Site map" onClick={handleChangeStatus} />
            <div className="body_section container" style={{ marginTop: "10%", marginBottom: "10%" }}>
                <div>
                    <div className='text-center mb-5'>
                        <h1>About Us</h1>
                    </div>
                    <div className='container row text-start'>
                        <div className='col-12 col-md-6 col-lg-4 p-3 text-uppercase'>
                            <Link rel="canonical" className='fs-4' to="/faq">FAQ</Link>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4 p-3 text-uppercase'>
                            <Link rel="canonical" className='fs-4' to="/privacy/policy">privacy</Link>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4 p-3 text-uppercase'>
                            <Link rel="canonical" className='fs-4' to="/terms">Terms</Link>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4 p-3 text-uppercase'>
                            <Link rel="canonical" className='fs-4' to="/joinus">Jobs</Link>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4 p-3 text-uppercase'>
                            <Link rel="canonical" className='fs-4' to="/contactus">contact</Link>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4 p-3 text-uppercase'>
                            <Link rel="canonical" className='fs-4' to="/blog">blog</Link>
                        </div>
                    </div>
                </div>
            </div>
            <HomeSidebar
                type="home"
                status={sidebarStatus}
                statusChange={setsidebarStatus}
            />
            <Footer />
        </div>
    )
}

export default SiteMap