import { ActionTypes } from "../../Constant/ActionType";
const initState = {
  SkuListing: {},
  SkuData: {},
  SkuOrderBy: "",
  SkuOrder: "",
  SkuFilterIcon: false,
  SkuEyeIcon: true,
  SkuFilterListing: {},
  SkuPage: 1,
  SkuPerpage: 50,
  Skumodal: 0
};
const SkuReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SetSkuListing:
      return {
        ...state,
        SkuListing: payload,
      };
    case ActionTypes.SetSkuOrderBy:
      return {
        ...state,
        SkuOrderBy: payload,
      };
    case ActionTypes.SetSkuOrder:
      return {
        ...state,
        SkuOrder: payload,
      };
    case ActionTypes.SetSkuData:
      return {
        ...state,
        SkuData: payload,
      };
    case ActionTypes.SetSkuFilterIcon:
      return {
        ...state,
        SkuFilterIcon: payload,
      };
    case ActionTypes.SetSkuEyeIcon:
      return {
        ...state,
        SkuEyeIcon: payload,
      };
    case ActionTypes.SetSkuFilterListing:
      return {
        ...state,
        SkuFilterListing: payload,
      };
    case ActionTypes.SetSkuPageListing:
      return {
        ...state,
        SkuPage: payload,
      };
    case ActionTypes.SetSkuPerPageListing:
      return {
        ...state,
        SkuPerpage: payload,
      };
    case ActionTypes.SetSkuFilterData:
      return {
        ...state,
        SkuFilter: payload,
      };
    case ActionTypes.SetSkuSelectedFilterData:
      return {
        ...state,
        SkuSelectedFilter: payload,
      };
    case ActionTypes.SetSkuListingModal:
      return {
        ...state,
        Skumodal: payload,
      };
    default:
      return state;
  }
};
export default SkuReducer;
