import { ActionTypes } from "../../Constant/ActionType";

// Filter Icon
export const SetHomeFilterIcon = (payload) => {
    return {
        type: ActionTypes.SetHomeFilterIcon,
        payload: payload,
    };
};

// Filter data
export const setHomeSearchKeyword = (payload) => {
    return {
        type: ActionTypes.setHomeSearchKeyword,
        payload: payload,
    };
};

// View or hide filter
export const SetHomeFilterEyeIcon = (payload) => {
    return {
        type: ActionTypes.SetHomeFilterEyeIcon,
        payload: payload,
    };
};

// Category Status
export const SetHomeCategoryStatus = (payload) => {
    return {
        type: ActionTypes.SetHomeCategoryStatus,
        payload: payload,
    };
};

// Filter Data
export const SetHomeFilterData = (payload) => {
    return {
        type: ActionTypes.SetHomeFilterData,
        payload: payload,
    };
};

// Selected Filter Data
export const SetHomeSelectedFilterData = (payload) => {
    return {
        type: ActionTypes.SetHomeSelectedFilterData,
        payload: payload,
    };
};

// Category Sneaker Expand
export const SetHomeCategorySneakerExpand = (payload) => {
    return {
        type: ActionTypes.SetHomeCategorySneakerExpand,
        payload: payload,
    };
};

// Category Apparel Expand
export const SetHomeCategoryApparelExpand = (payload) => {
    return {
        type: ActionTypes.SetHomeCategoryApparelExpand,
        payload: payload,
    };
};

// Category Status
export const SetHomeCategoryCollectibleExpand = (payload) => {
    return {
        type: ActionTypes.SetHomeCategoryCollectibleExpand,
        payload: payload,
    };
};

// Category Expand Id
export const SetHomeCategoryExpandId = (payload) => {
    return {
        type: ActionTypes.SetHomeCategoryExpandId,
        payload: payload,
    };
};

// Temporary Filter Data
export const SetHomeTempFilterData = (payload) => {
    return {
        type: ActionTypes.SetHomeTempFilterData,
        payload: payload,
    };
};

//Sorting Data
export const SetSortObject = (payload) => {
    return {
        type: ActionTypes.SetSortObject,
        payload: payload,
    };
};
export const SetSortViewBy = (payload) => {
    return {
        type: ActionTypes.SetSortViewBy,
        payload: payload,
    };
};
export const SetSort = (payload) => {
    return {
        type: ActionTypes.SetSort,
        payload: payload,
    };
};

export const SetViewBy = (payload) => {
    return {
        type: ActionTypes.SetViewBy,
        payload: payload,
    };
};
export const SetViewByApply = (payload) => {
    return {
        type: ActionTypes.SetViewByApply,
        payload: payload,
    };
};