export const ActionTypes = {
  //Register User Data
  RegisterUserData: "RegisterUserData",

  //Auth
  AuthUser: "AuthUser",
  AuthUserLogOut: "AuthUserLogOut",
  SignUpDealModel: "SignUpDealModel",

  //Loader
  SetLoadder: "SetLoadder",

  //Sku Listing
  SetSkuListing: "SetSkuListing",
  SetSkuData: "SetSkuData",
  SetSkuOrder: "SetSkuOrder",
  SetSkuOrderBy: "SetSkuOrderBy",
  SetSkuFilterIcon: "SetSkuFilterIcon",
  SetSkuEyeIcon: "SetSkuEyeIcon",
  SetSkuFilterListing: "SetSkuFilterListing",
  SetSkuPageListing: "SetSkuPageListing",
  SetSkuPerPageListing: "SetSkuPerPageListing",
  SetSkuListingModal: "SetSkuListingModal",

  //Non Auth Product
  SetProductListing: "SetProductListing",
  SetProductData: "SetProductData",
  SetProductOrder: "SetProductOrder",
  SetProductOrderBy: "SetProductOrderBy",
  SetProductFilterIcon: "SetProductFilterIcon",
  SetProductEyeIcon: "SetProductEyeIcon",
  SetProductFilterListing: "SetProductFilterListing",
  SetProductPageListing: "SetProductPageListing",
  SetProductPerPageListing: "SetProductPerPageListing",
  SetProductFilterData: "SetProductFilterData",
  SetProductSelectedFilterData: "SetProductSelectedFilterData",

  //Sku Filter
  SetSkuFilterData: "SetSkuFilterData",
  SetSkuSelectedFilterData: "SetSkuSelectedFilterData",
  SkuFilterDefaultData: "SkuFilterDefaultData",

  // Dashboard
  SetDashboardFilterData: "SetDashboardFilterData",
  SetDashboardSelectedFilterData: "SetDashboardSelectedFilterData",
  SetTempFilterData: "SetTempFilterData",
  SetFilterIcon: "SetFilterIcon",
  SetCategoryStatus: "SetCategoryStatus",
  setSearchKeyword: "setSearchKeyword",
  SetLabel: "SetLabel",
  SetFilterEyeIcon: "SetFilterEyeIcon",
  SetCategorySneakerExpand: "SetCategorySneakerExpand",
  SetCategoryApparelExpand: "SetCategoryApparelExpand",
  SetCategoryCollectibleExpand: "SetCategoryCollectibleExpand",
  SetCategoryExpandId: "SetCategoryExpandId",
  SetDashboardData: "SetDashboardData",
  SetCurrentPage: "SetCurrentPage",
  SetTotalPage: "SetTotalPage",

  // Home Actions
  SetHomeTempFilterData: "SetHomeTempFilterData",
  SetHomeFilterIcon: "SetHomeFilterIcon",
  setHomeSearchKeyword: "setHomeSearchKeyword",
  SetHomeFilterEyeIcon: "SetHomeFilterEyeIcon",
  SetHomeFilterData: "SetHomeFilterData",
  SetHomeCategoryStatus: "SetHomeCategoryStatus",
  SetHomeSelectedFilterData: "SetHomeSelectedFilterData",
  SetHomeCategorySneakerExpand: "SetHomeCategorySneakerExpand",
  SetHomeCategoryApparelExpand: "SetHomeCategoryApparelExpand",
  SetHomeCategoryCollectibleExpand: "SetHomeCategoryCollectibleExpand",
  SetHomeCategoryExpandId: "SetHomeCategoryExpandId",
  SetSortObject: "SetSortObject",
  SetSortViewBy: "SetSortViewBy",
  SetSort: "SetSort",
  SetViewBy: "SetViewBy",
  SetViewByApply: "SetViewByApply",

  //Bulk Deals Listing
  SetBulkDealSimilarListing: "SetBulkDealSimilarListing",

  //Promo Similar Product Listing
  SetPromoSimilarProductListing: "SetPromoSimilarProductListing",
};
