import { ActionTypes } from "../../Constant/ActionType";

export const SetSkuFilterData = (payload) => {
  return {
    type: ActionTypes.SetSkuFilterData,
    payload: payload,
  };
};
export const SetSkuSelectedFilterData = (payload) => {
  return {
    type: ActionTypes.SetSkuSelectedFilterData,
    payload: payload,
  };
};
export const SkuFilterDefaultData = (payload) => {
  return {
    type: ActionTypes.SkuFilterDefaultData,
    payload: payload,
  };
};
